@ -1,635 +1,637 @@
<template>
    <main class="motor-journey-main">
        <div class="container flow-container">
            <div class="flow-left-column">
                <div class="top-header">
                    <router-link to="/">
                        <div class="logo">
                            <img class="site-logo-meCompare" src="../../assets/images/logo/me-logo.svg">
                        </div>
                    </router-link>
                </div>
                <div class="flow-option-back">
                    <ul class="flow-option-back-ul">
                        <!-- <li class="flow-option-back-li" @click="back">
                            <i class="pi pi-arrow-left"></i>
                            Back
                        </li> -->
                    </ul>
                </div>

                <div class="flow-option-list">
                    <ul class="flow-option-list-ul">
                        <li class="flow-option-list-li"
                            v-bind:class="(user_medical_journey.step == 'coverage_type') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>1</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Coverage Type </p>
                                    <h4 class="flow-type-title">{{ user_medical_journey.coverage_type != null ? user_medical_journey.coverage_type.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li" v-bind:class="(user_medical_journey.step == 'city') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>2</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Emirates</p>
                                    <h4 class="flow-type-title">{{ user_medical_journey.uae_city != null ? user_medical_journey.uae_city.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>

                        <li class="flow-option-list-li" v-bind:class="(user_medical_journey.step == 'personal_details' || user_medical_journey.step == 'add_family_members' || user_medical_journey.step == 'family_member_details') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>3</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Insurers</p>
                                    <h4 class="flow-type-title" v-if="user_medical_journey.coverage_type != null && (user_medical_journey.coverage_type.id == 1 || user_medical_journey.coverage_type.id == 2)">
                                       1 Adult
                                    </h4>
                                    <h4 class="flow-type-title" v-else-if="user_medical_journey.coverage_type != null && (user_medical_journey.coverage_type.id == 3 || user_medical_journey.coverage_type.id == 4)">
                                        {{user_medical_journey.insured_peoples.adults_length }} Adults, {{
                                        user_medical_journey.insured_peoples.childs_length }} Childs
                                    </h4>
                                    <h4 class="flow-type-title" v-else>Not Selected</h4>

                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>

                        
                        <li class="flow-option-list-li" v-bind:class="(user_medical_journey.step == 'critical_medical_history') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>4</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Critical Medical History</p>
                                    <h4 class="flow-type-title">{{ user_medical_journey.critical_medical_history != null ? user_medical_journey.critical_medical_history.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>

                        <li class="flow-option-list-li" v-bind:class="(user_medical_journey.step == 'salary_range') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>5</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Salary Range</p>
                                    <h4 class="flow-type-title">{{ user_medical_journey.salary_range != null ? user_medical_journey.salary_range.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>

                        <li class="flow-option-list-li" v-bind:class="(user_medical_journey.step == 'current_active_policy') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>6</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Current Active Policy</p>
                                    <h4 class="flow-type-title">{{ user_medical_journey.current_active_policy != null ? user_medical_journey.current_active_policy.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li" v-bind:class="(user_medical_journey.step == 'current_insurance_company') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>7</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Policy Expiry Date</p>
                                    <h4 class="flow-type-title">{{ user_medical_journey.current_policy_expiry_date != null ? moment(user_medical_journey.current_policy_expiry_date).format("DD MMMM YYYY") : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="flow-right-column">
                <div class="top-header">
                    <header class="header-container">
                        <div class="container">
                            <div class="grid header-grid m-0">
                                <div class="header-left">
                                    <router-link to="/" class="journey-header-logo">
                                        <div class="logo">
                                            <img class="site-logo-spaceDesign" src="../../assets/images/logo/logo.png">
                                        </div>
                                    </router-link>
                                </div>
                                <div class="header-right header-right-desktop">
                                    <ul class="header-left-menuUl header-left-menuUlFirst">

                                        <li
                                            class="header-left-menuItems header-left-menuItemsServices menuItemsCompany">
                                            <p>
                                                <img src="../../assets/images/Icons/headphone-icon.svg">
                                                Support
                                                <i class="pi pi-angle-down"
                                                    style="font-size: 0.8rem;padding-left: 0.1rem;"></i>
                                            </p>

                                            <div class="mega-box">
                                                <div class="content">
                                                    <div class="grid w-full">
                                                        <div class="col-12 md:col-12 lg:col-12">
                                                            <router-link to="/">
                                                                <div class="mega-menu-list">
                                                                    <div class="mega-menu-list-info">
                                                                        <h4 class="mega-menu-title">FAQ</h4>
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </div>

                                                        <div class="col-12 md:col-12 lg:col-12">
                                                            <router-link to="/">
                                                                <div class="mega-menu-list border-0">
                                                                    <div class="mega-menu-list-info">
                                                                        <h4 class="mega-menu-title">Contact Us</h4>
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                    <ul class="header-left-menuUl header-left-menuUlBtn">
                                        <li
                                            class="header-left-menuItems header-left-menuItemsServices language-switcher">
                                            <selectButton-prime v-model="value" :options="options"
                                                aria-labelledby="basic" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div class="flow-steps-content-container">
                        <div class="progress-bar-container">
                            <div class="progress-head">
                                <p class="progress-bar-label back-btn-flow" @click="back">
                                    <i class="pi pi-arrow-left"></i>
                                    Back
                                </p>
                                <p class="progress-bar-label">{{user_medical_journey.progress_bar_percentage}}%</p>
                            </div>
                            <progressBar-prime :value="user_medical_journey.progress_bar_percentage"></progressBar-prime>
                        </div>

                        <!-- Flow Coverage Start -->
                        <div class="step-container five-flow flow-no-border height-auto medical-coverage-require-flow" v-if="user_medical_journey.step == 'coverage_type'">
                            <div class="flow-step-container border-0 p-0">
                                <h2 class="flow-title">Select the coverage you require</h2>

                                <div class="coverage-require-container">
                                    <dropdown-prime v-model="user_medical_journey.coverage_type" :options="coverage_types" optionLabel="name" placeholder="Select Coverage Require" class="w-full md:w-14rem" panelClass="dropdown-coverage-type" appendTo=".coverage-require-container">
                                        <template #option="slotProps">
                                            <div class="flex align-items-center" @click="set_value(slotProps.option, 'coverage_type')">
                                                <img :src="slotProps.option.icon" class="mr-2 coverage-icon" />
                                                <div class="dropdown-info">
                                                    <p>{{ slotProps.option.name }}</p>
                                                    <span>{{ slotProps.option.description }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </dropdown-prime>
                                </div>

                                <!-- <div class="insurance-type-container">
                                    <div class="coverage-type-box" v-for="item in coverage_types" :key="item" @click="set_value(item, 'coverage_type')">
                                        <div class="insurance-type-icon">
                                            <img :src="item.icon">
                                        </div>
                                        <div class="insurance-type-info">
                                            <h2 class="m-0">{{ item.name }}</h2>
                                            <p class="m-0">{{ item.description }}</p>
                                        </div>
                                    </div>
                                </div> -->
                            </div>

                            <!-- <button-prime class="flow-continuebtn" label="Continue" /> -->
                        </div>
                        <!-- / Flow Coverage End -->

                        <!-- Flow Select Emirates Start -->
                        <div class="step-container flow-select-emirates height-auto" v-if="user_medical_journey.step == 'city'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Select your Emirates</h2>

                                <div class="search-box-field">
                                    <IconField-prime iconPosition="right">
                                        <InputIcon-prime class="pi pi-search"> </InputIcon-prime>
                                        <inputText-prime @keyup="searchEmirate" v-model="searchemirate" placeholder="Search Emirate" />
                                    </IconField-prime>
                                </div>

                                <ScrollPanel-prime class="content-scroll-panel content-scroll-panel-travel mt-2">
                                    <div class="select-list-item emirate-list" v-for="item in uae_cities" :key="item" @click="set_value(item, 'city')">
                                        <div class="select-list-item-box mb-0">
                                            <h2 class="select-list-item-name">{{ item.name }}</h2>
                                        </div>
                                    </div>
                                </ScrollPanel-prime>
                            </div>
                        </div>
                        <!-- Flow Select Emirates End -->
                        

                        <!-- Flow Self Detail Start -->
                        <div class="step-container flow-self-details flow-medical-information" v-if="user_medical_journey.step == 'personal_details'">
                            <div class="flow-step-container border-0 p-0 mt-4 height-auto">
                                <h2 class="flow-title">Personal details</h2>

                                <div class="col-12 md:col-12 lg:col-12 getQuote-form-area mt-2">
                                    <div class="getQuote-form-area-inner grid m-0">
                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Full Name</label>
                                                <inputText-prime class="form-input-fields" v-model="user_medical_journey.customer.full_name" placeholder="Enter Full Name" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Mobile number</label>
                                                <vue-tel-input v-model="user_medical_journey.customer.phone_number"
                                                    mode="auto" :inputOptions="inputOptions"
                                                    :dropdownOptions="dropdownOptions" validCharactersOnly
                                                    :defaultCountry="default_country" @on-input="onInput"
                                                    @validate="valid"></vue-tel-input>
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Email</label>
                                                <inputText-prime class="form-input-fields" v-model="user_medical_journey.customer.email" placeholder="Enter Email" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Date of
                                                    Birth</label>
                                                    <calendar-prime v-model="user_medical_journey.customer.date_of_birth"
                                                    :maxDate="new Date(user_medical_journey.customer.backend_date_of_birth)" showIcon iconDisplay="input"
                                                    placeholder="Enter DOB" dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)" />
                                            </span>
                                        </div>

                                        <!-- <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Nationality</label>
                                                <dropdown-prime v-model="user_medical_journey.customer.nationality" :options="nationalities"
                                                    optionLabel="name" placeholder="Select Nationality"
                                                    class="w-full md:w-14rem" />
                                            </span>
                                        </div> -->

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Gender</label>
                                                <dropdown-prime v-model="user_medical_journey.customer.gender" :options="gender_categories"
                                                    optionLabel="name" placeholder="Select Gender"
                                                    class="Genderw-full md:w-14rem" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main" v-if="user_medical_journey.customer.gender != null && user_medical_journey.customer.gender.id!=1">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Are you
                                                    pregnent?</label>
                                                <dropdown-prime v-model="user_medical_journey.customer.is_pregnent" :options="pregnent_options"
                                                    optionLabel="name" placeholder="Select"
                                                    class="Genderw-full md:w-14rem" />
                                            </span>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>

                            <button-prime class="flow-continuebtn" label="Continue" @click="send_to_step('personal_details', 'critical_medical_history')" />
                        </div>
                        <!-- / Flow Self Detail End -->

                        <!-- Flow Members Start -->
                        <div class="step-container flow-add-member height-auto" v-if="user_medical_journey.step == 'add_family_members'">
                            <div class="flow-step-container border-0 p-0 mt-4">
                                <h2 class="flow-title">Add Family Members</h2>

                                <div class="travellers-info-container">
                                    <div class="travellers-info-box">
                                        <div class="traveller-label">
                                            <p>Adult</p>
                                            <label>18 - 70</label>
                                        </div>
                                        <div class="traveller-add-btns">
                                            <InputNumber-prime inputId="horizontal-buttons" showButtons :min="0" :max="100"
                                            v-model="user_medical_journey.insured_peoples.adults_length"
                                                buttonLayout="horizontal" :step="1">
                                                <template #incrementbuttonicon>
                                                    <span class="pi pi-plus" />
                                                </template>
                                                <template #decrementbuttonicon>
                                                    <span class="pi pi-minus" />
                                                </template>
                                            </InputNumber-prime>
                                        </div>
                                    </div>

                                    <div class="travellers-info-box border-0 pb-0">
                                        <div class="traveller-label">
                                            <p>Child</p>
                                            <label>0 - 17</label>
                                        </div>
                                        <div class="traveller-add-btns">
                                            <InputNumber-prime inputId="horizontal-buttons" showButtons :min="0" :max="100"
                                            v-model="user_medical_journey.insured_peoples.childs_length"
                                                buttonLayout="horizontal" :step="1">
                                                <template #incrementbuttonicon>
                                                    <span class="pi pi-plus" />
                                                </template>
                                                <template #decrementbuttonicon>
                                                    <span class="pi pi-minus" />
                                                </template>
                                            </InputNumber-prime>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button-prime class="flow-continuebtn" label="Continue"
                                @click="send_to_step('add_family_members', 'family_member_details')" />
                        </div>
                        <!-- Flow Members End -->

                        <!-- Flow Family Information Start -->
                        <div class="step-container flow-family-informations" v-if="user_medical_journey.step == 'family_member_details'">
                            <div class="flow-step-container border-0 p-0 mt-4">
                                <h2 class="flow-title">Enter Family details</h2>

                                <div class="travellers-info-container border-0 p-0">
                                    <accordion-prime expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                                        <accordionTab-prime v-for="(value, key) in user_medical_journey.insured_peoples.adults" :key="key" :header="(key==0 ? 'Adult '+(key+1)+' (Policy Holder)' : 'Adult '+(key+1))">
                                            <div class="col-12 md:col-12 lg:col-12 getQuote-form-area">
                                                <div class="getQuote-form-area-inner grid">
                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Full
                                                                Name</label>
                                                            <inputText-prime class="form-input-fields" v-model="user_medical_journey.insured_peoples.adults[key].full_name"
                                                                placeholder="Enter Full Name" />
                                                        </span>
                                                    </div>

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main" v-if="key==0">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Mobile
                                                                number</label>
                                                                <vue-tel-input v-model="user_medical_journey.insured_peoples.adults[key].phone_number"
                                                                mode="auto" :inputOptions="inputOptions"
                                                                :dropdownOptions="dropdownOptions" validCharactersOnly
                                                                :defaultCountry="default_country" @on-input="onInput"
                                                                @validate="valid"></vue-tel-input>
                                                        </span>
                                                    </div>

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main" v-if="key==0">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Email</label>
                                                            <inputText-prime class="form-input-fields" v-model="user_medical_journey.insured_peoples.adults[key].email" placeholder="Enter Email" />
                                                        </span>
                                                    </div>

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Date of
                                                                Birth</label>
                                                            <calendar-prime class="form-input-fields" v-model="user_medical_journey.insured_peoples.adults[key].date_of_birth"
                                                                :maxDate="new Date(user_medical_journey.insured_peoples.adults[key].backend_date_of_birth)" showIcon iconDisplay="input" dateFormat="dd MM yy"
                                                                placeholder="Enter DOB" />
                                                        </span>
                                                    </div>

                                                    <!-- <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label
                                                                class="input-label input-label-floating">Nationality</label>
                                                            <dropdown-prime v-model="user_medical_journey.insured_peoples.adults[key].nationality" :options="nationalities"
                                                                optionLabel="name" placeholder="Enter Nationality"
                                                                class="w-full md:w-14rem" />
                                                        </span>
                                                    </div> -->

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label
                                                                class="input-label input-label-floating">Gender</label>
                                                            <dropdown-prime v-model="user_medical_journey.insured_peoples.adults[key].gender" :options="gender_categories"
                                                                optionLabel="name" placeholder="Enter Gender"
                                                                class="Genderw-full md:w-14rem" />
                                                        </span>
                                                    </div>

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main" v-if="user_medical_journey.insured_peoples.adults[key].gender != null && user_medical_journey.insured_peoples.adults[key].gender.id!=1">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Are you
                                                                pregnent?</label>
                                                            <dropdown-prime v-model="user_medical_journey.insured_peoples.adults[key].is_pregnent"
                                                                :options="pregnent_options" optionLabel="name"
                                                                placeholder="Select" class="Genderw-full md:w-14rem" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </accordionTab-prime>
                                        <accordionTab-prime v-for="(value, key) in user_medical_journey.insured_peoples.childs" :key="key" :header="'Child '+(key+1)">
                                            <div class="col-12 md:col-12 lg:col-12 getQuote-form-area">
                                                <div class="getQuote-form-area-inner grid">
                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Full
                                                                Name</label>
                                                            <inputText-prime class="form-input-fields" v-model="user_medical_journey.insured_peoples.childs[key].full_name"
                                                                placeholder="Enter Full Name" />
                                                        </span>
                                                    </div>

                                                    
                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Date of
                                                                Birth</label>
                                                            <calendar-prime class="form-input-fields" v-model="user_medical_journey.insured_peoples.childs[key].date_of_birth"
                                                            :maxDate="new Date(user_medical_journey.insured_peoples.childs[key].backend_date_of_birth)" showIcon iconDisplay="input" dateFormat="dd MM yy"
                                                                placeholder="Enter DOB" />
                                                        </span>
                                                    </div>

                                                    <!-- <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label
                                                                class="input-label input-label-floating">Nationality</label>
                                                            <dropdown-prime v-model="user_medical_journey.insured_peoples.childs[key].nationality" :options="nationalities"
                                                                optionLabel="name" placeholder="Enter Nationality"
                                                                class="w-full md:w-14rem" />
                                                        </span>
                                                    </div> -->

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label
                                                                class="input-label input-label-floating">Gender</label>
                                                            <dropdown-prime v-model="user_medical_journey.insured_peoples.childs[key].gender" :options="gender_categories"
                                                                optionLabel="name" placeholder="Enter Gender"
                                                                class="Genderw-full md:w-14rem" />
                                                        </span>
                                                    </div>

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main" v-if="user_medical_journey.insured_peoples.childs[key].gender != null && user_medical_journey.insured_peoples.childs[key].gender.id!=1">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Are you
                                                                pregnent?</label>
                                                            <dropdown-prime v-model="user_medical_journey.insured_peoples.childs[key].is_pregnent"
                                                                :options="pregnent_options" optionLabel="name"
                                                                placeholder="Select" class="Genderw-full md:w-14rem" />
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </accordionTab-prime>
                                    </accordion-prime>
                                </div>
                            </div>

                            <button-prime class="flow-continuebtn" label="Continue" @click="send_to_step('family_member_details', 'critical_medical_history')" />
                        </div>
                        <!-- Flow Members End -->

                       


                        <!-- Flow Medical History Start -->
                        <div class="step-container travel-second-flow height-auto medica-history-flow" v-if="user_medical_journey.step == 'critical_medical_history'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Do you have any critical medical history?</h2>

                                <!-- <ScrollPanel-prime class="content-scroll-panel mb-4"> -->
                                <div class="select-button-container mb-4 mt-4">
                                    <selectButton-prime v-model="user_medical_journey.critical_medical_history" :options="critical_medical_history_options"
                                        optionLabel="name" aria-labelledby="" />
                                </div>
                                <!-- </ScrollPanel-prime> -->

                                <h2 class="flow-title">Disclaimer</h2>

                                <p class="text-center">I hereby declare that the information provided in this medical
                                    insurance application
                                    is accurate and complete to the best of my knowledge. I understand that any
                                    misrepresentation or omission may affect the validity of the policy. I authorize the
                                    insurance provider to verify the details provided and process this application in
                                    accordance with their policies and regulations.</p>

                                <button-prime label="Agree & Continue" class="agree-btn" :loading="agree_and_continue_loader"
                                    @click="send_to_step('critical_medical_history', 'salary_range')" />
                            </div>
                        </div>
                        <!-- Flow Medical History End -->

                        <!-- Flow salary range Start -->
                        <div class="step-container three-flow height-auto" v-if="user_medical_journey.step == 'salary_range'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Select your salary range</h2>

                                <div class="select-list-item">
                                    <div class="select-list-item-box range-salary-box" v-for="item in salaries" :key="item" @click="set_value(item, 'salary_range')">
                                        <h2 class="salary-range-number">{{ item.name }}</h2>
                                        <!-- <h2 class="salary-name">AED</h2> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- / Flow salary ranges End -->

                         <!-- Flow Select CoPay Start -->
                         <div class="step-container flow-select-emirates height-auto" v-if="user_medical_journey.step == 'co_pay'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Please specify your preferences, These will contribute to your premium</h2>

                                <ScrollPanel-prime class="content-scroll-panel mt-2">
                                    <div class="select-button-container copay-multiselect mb-4 mt-4">
                                        <selectButton-prime v-model="user_medical_journey.co_pay_option" :options="co_pay_options"
                                          aria-labelledby="" optionLabel="percentage" multiple>
                                          <template #option="slotProps">
                                            <span>{{slotProps.option.percentage}} {{ slotProps.option.label }}</span>
                                        </template>
                                        </selectButton-prime>
                                            
                                    </div>
                                </ScrollPanel-prime>

                                <button-prime class="flow-continuebtn mt-0" label="Continue" @click="send_to_step('co_pay', 'current_active_policy')" />
                            </div>
                        </div>
                        <!-- Flow Select CoPay End -->

                        <!-- Flow Policy Start -->
                        <div class="step-container fourth-flow height-auto" v-if="user_medical_journey.step == 'current_active_policy'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Do you have current active policy?</h2>

                                <ScrollPanel-prime class="content-scroll-panel mt-2">
                                    <div class="select-list-item" v-for="item in current_active_policy_options" :key="item" @click="set_value(item, 'current_active_policy')">
                                        <div class="select-list-item-box mb-0">
                                            <h2 class="select-list-item-name">{{ item.name }}</h2>
                                        </div>
                                    </div>
                                </ScrollPanel-prime>
                            </div>
                        </div>
                        <!-- Flow Policy End -->

                        <!-- Flow Insurance Date Start -->
                        <div class="step-container five-flow flow-insurance-date flow-no-border" v-if="user_medical_journey.step == 'current_insurance_company'">
                            <div class="flow-step-container border-0 p-0 height-auto ">
                                <h2 class="flow-title">What is your current Insurance Company?</h2>

                                <div class="col-12 md:col-12 lg:col-12 getQuote-form-area">
                                    <div class="getQuote-form-area-inner grid m-0">
                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main p-0">
                                            <span class="p-input-icon-left">
                                                <dropdown-prime v-model="user_medical_journey.current_insurance_company" :options="companies"
                                                    optionLabel="name"
                                                    placeholder="Select your current insurance company"
                                                    class="w-full md:w-14rem" filter />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main p-0 mb-0">
                                            <span class="p-input-icon-left">
                                                <calendar-prime v-model="user_medical_journey.current_policy_expiry_date"
                                                showIcon iconDisplay="input"
                                                :minDate="user_medical_journey.current_policy_expiry_date"
                                                dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <button-prime class="flow-continuebtn" label="Continue" :loading=loading @click="save_quotation(false)" />
                            
                        </div>
                        <!-- / Flow Insurance Date End -->
                    </div>
                </div>
            </div>
        </div>
    </main>

    <toast-prime />

    <getAgentHelp />

    <dialog-prime v-model:visible="active_policy_alert_modal" header="ALERT" :style="{ width: '25rem' }" position="BottomRight"
        :modal="true" :draggable="false" class="eligible-popup">
        <span class="p-text-secondary block mb-5">You are not eligible for online policy purchase, Kindly contact us
            (whatsapp, email, call) to get detailed pricing.</span>
        <div class="flex justify-content-end gap-2">
            <a :href="'mailto:'+company_email"><button-prime class="support-chatnow-btn" label="Email"
                    icon="pi pi-envelope" /></a>
            <a :href="'https://wa.me/'+company_whatsapp_number"><button-prime class="support-chatnow-btn"
                    label="Whatsapp" icon="pi pi-whatsapp" /></a>
            <a :href="'tel:'+company_phone_number"><button-prime class="support-chatnow-btn" label="Call"
                    icon="pi pi-phone" /></a>
        </div>
    </dialog-prime>

    <!-- Edit Travel Destination Modal -->
    <dialog-prime v-model:visible="visibleThankYouModal" modal header="" class="thank-you-modal" :style="{ width: '30rem' }">
        <div class="thank-you-modal-body">
            <h2>Thank You</h2>
            <p>Our agents will contact you for further support</p>
        </div>
        <div class="flex justify-content-center">
            <router-link to="/medical">
                <button-prime  type="button" label="Continue Browsing" @click="visibleThankYouModal = false"></button-prime>
            </router-link>
        </div>
    </dialog-prime>
    <!-- / Edit Travel Destination Modal -->
</template>

<script>
import getAgentHelp from '../getAgentHelp.vue'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'

export default {
    components: {
        getAgentHelp,
    },
    data() {
        return {
            searchemirate : null,
            visibleThankYouModal: false,
            agree_and_continue_loader : false,
            active_policy_alert_modal : false,
            is_phone_number_valid : false,
            loading : false,
            coverage_types : [],
            gender_categories : [],
            uae_cities : [],
            nationalities : [],
            salaries : [],
            co_pay_options : [],
            pregnent_options : [{name : 'Yes'}, {name : 'No'}],
            critical_medical_history_options : [{name : 'Yes'}, {name : 'No'}],
            current_active_policy_options : [{name : 'Yes'}, {name : 'No'}],
            companies : [],
            user_medical_journey : {
                status : 'lead',
                quotation_id : null,
                quotation_number : null,
                step : 'coverage_type',
                coverage_type : null,
                uae_city : null,
                critical_medical_history : null,
                salary_range : null,
                co_pay_option : [],
                current_active_policy : null,
                current_insurance_company : null,
                current_policy_expiry_date : null,
                progress_bar_percentage : 0,
                progress_bar_per_question : 100/6,
                progress_obtained_mark : 0,
                total_questions : 6,
                add_or_subtract_number : 16.66,
                insured_peoples : {
                    adults_length : 1,
                    childs_length : 0,
                    adults : [],
                    childs : [],
                },
                customer : {
                    full_name : null,
                    email : null,
                    phone_number : localStorage.getItem("mebrokers.customer.phone.number") == null ? null : localStorage.getItem("mebrokers.customer.phone.number"),
                    date_of_birth : null,
                    backend_date_of_birth : new Date(new Date().setTime(new Date().valueOf() - 19 * 365 * 24 * 60 * 60 * 1000)), 
                    nationality : null,
                    gender : null,
                    is_pregnent : null
                },
            }
        }
    },
    mounted() {
        $.expr[":"].contains = $.expr.createPseudo(function(arg) {
            return function( elem ) {
                return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
            };
        });
        this.get_coverage_types();
        this.get_gender_categories();
        this.get_uae_cities();
        // this.get_nationalities();
        this.get_salary_options();
        this.get_companies();
        this.get_co_pay_options();
        if (this.$route.query.quotation_id != undefined && this.$route.query.quotation_id != '') {
            this.get_quotation_details(this.$route.query.quotation_id);
        } else if (localStorage.getItem('mebrokers.customer.medical.journey') != null) {
            try {
                this.user_medical_journey = JSON.parse(localStorage.getItem('mebrokers.customer.medical.journey'));
                console.log('this.user_medical_journey', this.user_medical_journey);
                this.user_medical_journey.customer.date_of_birth = new Date(this.user_medical_journey.customer.date_of_birth);
                this.user_medical_journey.current_policy_expiry_date = new Date(this.user_medical_journey.current_policy_expiry_date);
                this.calculate_percentage();
                
            } catch(err) {
                localStorage.removeItem('mebrokers.customer.medical.journey');
                localStorage.removeItem('mebrokers.customer.medical.plans.comparison');
            }
        }
    },
    methods: {
        searchEmirate : function() {
            $('.emirate-list').removeClass('hidden');
            $('.emirate-list:not(:contains('+ this.searchemirate +'))').addClass('hidden');
        },
        number_format : function(number) {
            number = parseFloat(number);
            return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },
        get_quotation_details : function(quotation_id) {
            axios.get(this.api_url+'medical/get_quotation_details', {
                params : {
                    quotation_id : quotation_id
                }
            }).then((response) => {
                var quotation = response.data.data.quotation;
                localStorage.removeItem('mebrokers.customer.medical.journey');
                this.user_medical_journey.quotation_id = quotation.unique_id;
                this.user_medical_journey.quotation_number = quotation.quotation_number;
                this.user_medical_journey.step = 'coverage_type';
                
                for (let i = 0; i < this.coverage_types.length; i++) {
                    if (quotation.coverage_type_id == this.coverage_types[i].id) {
                        this.user_medical_journey.coverage_type = this.coverage_types[i];
                    }
                }

                for (let i = 0; i < this.uae_cities.length; i++) {
                    if (quotation.city_id == this.uae_cities[i].id) {
                        this.user_medical_journey.uae_city = this.uae_cities[i];
                    }
                }

                for (let i = 0; i < this.salaries.length; i++) {
                    if (quotation.salary_range_id == this.salaries[i].id) {
                        this.user_medical_journey.salary_range = this.salaries[i];
                    }
                }

                this.user_medical_journey.critical_medical_history = (this.user_medical_journey.critical_medical_history == 'Yes' ? {name : 'Yes'} : {name : 'No'})
                this.user_medical_journey.current_active_policy = (this.user_medical_journey.current_active_policy == 'Yes' ? {name : 'Yes'} : {name : 'No'})
                this.user_medical_journey.current_policy_expiry_date = new Date(quotation.current_policy_expiry_date);
                this.user_medical_journey.current_policy_expiry_date = moment(this.user_medical_journey.current_policy_expiry_date).format("DD MMMM YYYY")
                this.user_medical_journey.status = quotation.status;
                for (let i = 0; i < this.companies.length; i++) {
                    if (quotation.current_insurance_company.id == this.companies[i].id) {
                        this.user_medical_journey.current_insurance_company = this.companies[i];
                    }
                } 
                if (this.user_medical_journey.coverage_type.id == 1 || this.user_medical_journey.coverage_type.id == 2) {
                    this.user_medical_journey.customer.full_name =  quotation.full_name;
                    this.user_medical_journey.customer.email = quotation.email;
                    this.user_medical_journey.customer.phone_number = quotation.mobile;
                    this.user_medical_journey.customer.date_of_birth = new Date(quotation.date_of_birth);
                    for (let i = 0; i < this.nationalities.length; i++) {
                        if (quotation.nationality_id == this.nationalities[i].id) {
                            this.user_medical_journey.customer.nationality = this.nationalities[i];
                        }
                    }
                    for (let i = 0; i < this.gender_categories.length; i++) {
                        if (quotation.gender_id == this.gender_categories[i].id) {
                            this.user_medical_journey.customer.gender = this.gender_categories[i];
                        }
                    }
                    this.user_medical_journey.customer.is_pregnent = quotation.is_pregnent == "Yes" ? {name : 'Yes'} : {name : 'No'}
                } else if (this.user_medical_journey.coverage_type.id == 3 || this.user_medical_journey.coverage_type.id == 4) {
                    var adult_length = 0;
                    var child_length = 0;
                    // var nat = {};
                    var gen = {};
                    for (let i = 0; i < quotation.insured_peoples.length; i++) {
                        if (quotation.insured_peoples[i].type == 'adult') {
                            adult_length+=1;
                            // nat = {};
                            gen = {};
                            // for (let j = 0; j < this.nationalities.length; j++) {
                            //     if (quotation.insured_peoples[i].nationality_id == this.nationalities[j].id) {
                            //         nat = this.nationalities[j];
                            //     }
                            // }
                            for (let j = 0; j < this.gender_categories.length; j++) {
                                if (quotation.insured_peoples[i].gender_id == this.gender_categories[j].id) {
                                    gen = this.gender_categories[j];
                                }
                            }
                            
                            this.user_medical_journey.insured_peoples.adults.push({
                                'full_name' : quotation.insured_peoples[i].full_name,
                                'is_policy_holder' : quotation.insured_peoples[i].policy_holder == 1 ? 1 : 0,
                                'type' : 'adult',
                                'email' : quotation.insured_peoples[i].email,
                                'phone_number' : quotation.insured_peoples[i].mobile,
                                'date_of_birth' : new Date(quotation.insured_peoples[i].date_of_birth),
                                // 'nationality' : nat,
                                'gender' : gen,
                                'is_pregnent' : quotation.insured_peoples[i].is_pregnent == "Yes" ? {name : 'Yes'} : {name : 'No'}
                            });
                            
                        }
                        if (quotation.insured_peoples[i].type == 'child') {
                            child_length+=1;
                            // nat = {};
                            gen = {};
                            // for (let j = 0; j < this.nationalities.length; j++) {
                            //     if (quotation.insured_peoples[i].nationality_id == this.nationalities[j].id) {
                            //         nat = this.nationalities[j];
                            //     }
                            // }
                            for (let j = 0; j < this.gender_categories.length; j++) {
                                if (quotation.insured_peoples[i].gender_id == this.gender_categories[j].id) {
                                    gen = this.gender_categories[j];
                                }
                            }
                            quotation.insured_peoples[i].is_pregnent == "Yes" ? {name : 'Yes'} : {name : 'No'}
                            this.user_medical_journey.insured_peoples.childs.push({
                                'full_name' : quotation.insured_peoples[i].full_name,
                                'is_policy_holder' : 0,
                                'type' : 'child',
                                'date_of_birth' : new Date(quotation.insured_peoples[i].date_of_birth),
                                // 'nationality' : nat,
                                'gender' : gen,
                                'is_pregnent' : quotation.insured_peoples[i].is_pregnent == "Yes" ? {name : 'Yes'} : {name : 'No'}
                            });
                        }
                    }
                    this.user_medical_journey.insured_peoples.adults_length = adult_length;
                    this.user_medical_journey.insured_peoples.childs_length = child_length;
                }
                

                
                
            });
        },
        calculate_percentage : function() {
            
            var num = 0;
            if (this.user_medical_journey.step == 'coverage_type') {
                num = 0;
            }
            if (this.user_medical_journey.step == 'city') {
                num = 1;
            }
            if (this.user_medical_journey.step == 'personal_details' || this.user_medical_journey.step == 'add_family_members' || this.user_medical_journey.step == 'family_member_details') {
                num = 2;
            }
            if (this.user_medical_journey.step == 'critical_medical_history') {
                num = 3;
            }
            if (this.user_medical_journey.step == 'salary_range') {
                num = 4;
            }
            if (this.user_medical_journey.step == 'current_active_policy') {
                num = 5;
            }
            if (this.user_medical_journey.step == 'current_insurance_company') {
                num = 6;
            }

            this.user_medical_journey.progress_obtained_mark = this.user_medical_journey.progress_bar_per_question*num;
            this.user_medical_journey.progress_bar_percentage = Math.round(this.user_medical_journey.progress_obtained_mark/(this.user_medical_journey.total_questions*this.user_medical_journey.progress_bar_per_question)*100)

        },
        save_quotation : function(ignore_validation) {
            if (!ignore_validation && this.user_medical_journey.current_insurance_company == null) {
                this.$toast.add({ severity: 'error', detail: 'Current Company is required!', life: 3000 });
                return false; 
            }
            var objt = {};
            objt.customer = {};
            if (this.user_medical_journey.coverage_type.id == 1 || this.user_medical_journey.coverage_type.id == 2) {
                
                objt.customer.full_name = this.user_medical_journey.customer.full_name;
                objt.customer.email = this.user_medical_journey.customer.email;
                objt.customer.mobile = this.user_medical_journey.customer.phone_number;
                objt.customer.date_of_birth = moment(this.user_medical_journey.customer.date_of_birth).format("YYYY-MM-DD")
                // objt.customer.nationality = this.user_medical_journey.customer.nationality;
                objt.customer.gender = this.user_medical_journey.customer.gender;
                objt.customer.is_pregnent = this.user_medical_journey.customer.is_pregnent == null ? {name : 'No'} : this.user_medical_journey.customer.is_pregnent;
                
                
                objt.insured_peoples = [];
                objt.insured_peoples[0] = objt.customer;
                objt.insured_peoples[0].is_policy_holder = 1;
                objt.insured_peoples[0].type = 'adult';
                // objt.insured_peoples[0].nationality = this.user_medical_journey.customer.nationality
                objt.insured_peoples[0].gender = this.user_medical_journey.customer.gender
                objt.insured_peoples[0].is_pregnent = this.user_medical_journey.customer.is_pregnent == null ? {name : 'No'} : this.user_medical_journey.customer.is_pregnent

                

                objt.status = this.user_medical_journey.status;
                objt.quotation_id = this.user_medical_journey.quotation_id;
                objt.coverage_type_id = this.user_medical_journey.coverage_type.id;
                objt.salary_range_id = this.user_medical_journey.salary_range == null ? 0 : this.user_medical_journey.salary_range.id;
                objt.critical_medical_history = this.user_medical_journey.critical_medical_history.name;
                objt.current_active_policy = this.user_medical_journey.current_active_policy == null ? 'No' : this.user_medical_journey.current_active_policy.name;
                objt.current_insurance_company = this.user_medical_journey.current_insurance_company == null ? 0 : this.user_medical_journey.current_insurance_company.id;
                objt.current_policy_expiry_date = moment(this.user_medical_journey.current_policy_expiry_date).format("YYYY-MM-DD")
                objt.city_id = this.user_medical_journey.uae_city.id;
                

            } else if (this.user_medical_journey.coverage_type.id == 3 || this.user_medical_journey.coverage_type.id == 4) {
                objt.customer.full_name = this.user_medical_journey.insured_peoples.adults[0].full_name;
                objt.customer.email = this.user_medical_journey.insured_peoples.adults[0].email;
                objt.customer.mobile = this.user_medical_journey.insured_peoples.adults[0].phone_number;
                objt.customer.date_of_birth = moment(this.user_medical_journey.insured_peoples.adults[0].date_of_birth).format("YYYY-MM-DD")
                // objt.customer.nationality = this.user_medical_journey.insured_peoples.adults[0].nationality;
                objt.customer.gender = this.user_medical_journey.insured_peoples.adults[0].gender;
                objt.customer.is_pregnent = this.user_medical_journey.insured_peoples.adults[0].is_pregnent == null ? {name : 'No'} : this.user_medical_journey.insured_peoples.adults[0].is_pregnent;
                objt.insured_peoples = this.user_medical_journey.insured_peoples.adults.concat(this.user_medical_journey.insured_peoples.childs); 
               
                
                for (let i = 0; i < objt.insured_peoples.length; i++) {
                    objt.insured_peoples[i].mobile = objt.insured_peoples[i].phone_number != null && objt.insured_peoples[i].phone_number != undefined ? objt.insured_peoples[i].phone_number : null;
                    objt.insured_peoples[i].is_pregnent = objt.insured_peoples[i].is_pregnent == null ? {name : 'No'} : objt.insured_peoples[i].is_pregnent;
                }

                objt.status = this.user_medical_journey.status;
                objt.quotation_id = this.user_medical_journey.quotation_id;
                objt.coverage_type_id = this.user_medical_journey.coverage_type.id;
                objt.salary_range_id = this.user_medical_journey.salary_range == null ? 0 : this.user_medical_journey.salary_range.id;
                objt.critical_medical_history = this.user_medical_journey.critical_medical_history.name;
                objt.current_active_policy = this.user_medical_journey.current_active_policy == null ? 'No' : this.user_medical_journey.current_active_policy.name;
                objt.current_insurance_company = this.user_medical_journey.current_insurance_company == null ? 0 : this.user_medical_journey.current_insurance_company.id;
                objt.current_policy_expiry_date = moment(this.user_medical_journey.current_policy_expiry_date).format("YYYY-MM-DD")
                objt.city_id = this.user_medical_journey.uae_city.id;
                
            }

            objt.co_pay = this.user_medical_journey.co_pay_option;
            
            this.loading = true;
            
            console.log('ignore_validation', ignore_validation  )
            if (ignore_validation) {
                this.agree_and_continue_loader = true;
            }
            axios.get(this.api_url+'medical/save_quotation', {
                params : objt
            }).then((response) => {
                console.log('ignore_validation11', ignore_validation  )
                if (ignore_validation) {
                    this.agree_and_continue_loader = false;
                    this.visibleThankYouModal = true;
                    return false;
                }
                this.loading = false;
                this.user_medical_journey.quotation_id = response.data.data.quotation_id;
                this.user_medical_journey.quotation_number = response.data.data.quotation_number;
                localStorage.setItem('mebrokers.customer.medical.journey', JSON.stringify(this.user_medical_journey))
                localStorage.setItem('mebrokers.customer.phone.number', this.user_medical_journey.customer.phone_number);
                this.loading = false;
                if (this.$route.query.is_from != undefined && this.$route.query.is_from == 'admin') {
                    this.$router.push({
                        path : '/medical/plan-listing/'+this.user_medical_journey.quotation_id,
                        query : {
                            is_from : 'admin'
                        }
                    });
                } else {
                    this.$router.push({
                        path : '/medical/plan-listing/'+this.user_medical_journey.quotation_id,
                    });
                }
            });
        },
        set_value : function(item, type) {
            if (type == 'coverage_type') {
                this.user_medical_journey.coverage_type = item;
                this.send_to_step('coverage_type', 'city');    
            }
            if (type == 'city') {
                this.user_medical_journey.uae_city = item;
                if (this.user_medical_journey.coverage_type.id == 1 || this.user_medical_journey.coverage_type.id == 2) {
                    this.send_to_step('city', 'personal_details');    
                } else if (this.user_medical_journey.coverage_type.id == 3 || this.user_medical_journey.coverage_type.id == 4) {
                    this.send_to_step('city', 'add_family_members');    
                }
                
            }
            if (type == 'critical_medical_history') {
                // this.user_medical_journey.critical_medical_history = item;
                this.send_to_step('coverage_type', 'salary_range');    
            }

            if (type == 'salary_range') {
                this.user_medical_journey.salary_range = item;
                this.send_to_step('salary_range', 'current_active_policy');    
            }

            // if (type == 'salary_range') {
            //     this.user_medical_journey.salary_range = item;
            //     this.send_to_step('salary_range', 'co_pay');    
            // }

            if (type == 'current_active_policy') {
                this.user_medical_journey.current_active_policy = item;
                this.user_medical_journey.current_policy_expiry_date = new Date();
                this.send_to_step('current_active_policy', 'current_insurance_company');    
            }
        },
        send_to_step : function(from, to) {
            
            
            
            if (from == 'personal_details' && to == 'critical_medical_history') {
                if (this.user_medical_journey.customer.full_name == null || this.user_medical_journey.customer.full_name == '') {
                    this.$toast.add({ severity: 'error', detail: 'Full name is required!', life: 3000 });
                    return false; 
                }

                if (this.user_medical_journey.customer.full_name.trim().split(/\s+/) && this.user_medical_journey.customer.full_name.trim().split(/\s+/).length < 2) {
                    this.$toast.add({ severity: 'error', detail: "Full name is required!", life: 3000 });
                    return false;
                }

                if (!this.is_phone_number_valid) {
                    this.$toast.add({ severity: 'error', detail: 'Invalid Phone number!', life: 3000 });
                    return false; 
                }

                if (this.user_medical_journey.customer.email == null || this.user_medical_journey.customer.email == '') {
                    this.$toast.add({ severity: 'error', detail: 'Email is required!', life: 3000 });
                    return false; 
                }

                if (!this.validate_email(this.user_medical_journey.customer.email)) {
                    this.$toast.add({ severity: 'error', detail: 'Incorrect email!', life: 3000 });
                    return false; 
                }

                if (this.user_medical_journey.customer.date_of_birth == null) {
                    this.$toast.add({ severity: 'error', detail: 'Birth date is required!', life: 3000 });
                    return false; 
                }

                // if (this.user_medical_journey.customer.nationality == null) {
                //     this.$toast.add({ severity: 'error', detail: 'Nationality is required!', life: 3000 });
                //     return false; 
                // }

                if (this.user_medical_journey.customer.gender == null) {
                    this.$toast.add({ severity: 'error', detail: 'Gender is required!', life: 3000 });
                    return false; 
                }

                if (this.user_medical_journey.customer.gender != null && this.user_medical_journey.customer.gender.id != 1 && this.user_medical_journey.customer.is_pregnent == null) {
                    this.$toast.add({ severity: 'error', detail: 'Pregnent option is required!', life: 3000 });
                    return false; 
                }

            }

            if (from == 'critical_medical_history' && to == 'salary_range') {
                if (this.user_medical_journey.critical_medical_history == null) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the option!', life: 3000 });
                    return false; 
                }
                if (this.user_medical_journey.critical_medical_history != null && this.user_medical_journey.critical_medical_history.name == 'Yes') {
                    this.save_quotation(true);
                    return false;
                }
            }

            if (from == 'current_active_policy' && to == 'current_insurance_company') {
                if (this.user_medical_journey.current_active_policy !== null && this.user_medical_journey.current_active_policy.name == 'No') {
                    this.active_policy_alert_modal = true;
                    return false; 
                }
            }

            
            if (from == 'add_family_members' && to == 'family_member_details') {
                if (this.user_medical_journey.insured_peoples.adults_length == 0) {
                    this.$toast.add({ severity: 'error', detail: '1 Adult is required!', life: 3000 });
                    return false; 
                }
                if (this.user_medical_journey.insured_peoples.adults.length != this.user_medical_journey.insured_peoples.adults_length) {
                    this.user_medical_journey.insured_peoples.adults = [];
                    for (let i = 0; i < this.user_medical_journey.insured_peoples.adults_length; i++) {
                        this.user_medical_journey.insured_peoples.adults.push({
                            'full_name' : null,
                            'is_policy_holder' : i == 0 ? 1 : 0,
                            'type' : 'adult',
                            'email' : null,
                            'phone_number' : localStorage.getItem("mebrokers.customer.phone.number") == null ? null : localStorage.getItem("mebrokers.customer.phone.number"),
                            'date_of_birth' : null,
                            'backend_date_of_birth' : i == 0 ? new Date(moment().subtract(19, 'years').format("YYYY-MM-DD")) : new Date(),
                            // 'nationality' : null,
                            'gender' : null,
                            'is_pregnent' : null
                        });
                    }
                } else {
                    for (let i = 0; i < this.user_medical_journey.insured_peoples.adults_length; i++) {
                        if (this.user_medical_journey.insured_peoples.adults[i].date_of_birth != null) {
                            this.user_medical_journey.insured_peoples.adults[i].date_of_birth = new Date(this.user_medical_journey.insured_peoples.adults[i].date_of_birth)
                        }
                    }
                }    
                if (this.user_medical_journey.insured_peoples.childs.length != this.user_medical_journey.insured_peoples.childs_length) {
                    this.user_medical_journey.insured_peoples.childs = [];
                    for (let i = 0; i < this.user_medical_journey.insured_peoples.childs_length; i++) {
                        this.user_medical_journey.insured_peoples.childs.push({
                            'full_name' : null,
                            'is_policy_holder' : 0,
                            'type' : 'child',
                            'date_of_birth' : null,
                            'backend_date_of_birth' : new Date(),
                            // 'nationality' : null,
                            'gender' : null,
                            'is_pregnent' : null
                        });
                    }
                } else {
                    for (let i = 0; i < this.user_medical_journey.insured_peoples.childs_length; i++) {
                        if (this.user_medical_journey.insured_peoples.childs[i].date_of_birth != null) {
                            this.user_medical_journey.insured_peoples.childs[i].date_of_birth = new Date(this.user_medical_journey.insured_peoples.childs[i].date_of_birth)
                        }
                    }
                }
                
                
            }

            if (from == 'family_member_details' && to == 'critical_medical_history') {
                var array_to_validate = this.user_medical_journey.insured_peoples.adults.concat(this.user_medical_journey.insured_peoples.childs); 
                for (let i = 0; i < array_to_validate.length; i++) {
                    if (array_to_validate[i].full_name == null) {
                        this.$toast.add({ severity: 'error', detail: "full name is required!", life: 3000 });
                        return false;
                    }

                    if (array_to_validate[i].full_name.trim().split(/\s+/) && array_to_validate[i].full_name.trim().split(/\s+/).length < 2) {
                        this.$toast.add({ severity: 'error', detail: "full name is required!", life: 3000 });
                        return false;
                    }

                    if (array_to_validate[i].is_policy_holder == 1 && !this.is_phone_number_valid) {
                        this.$toast.add({ severity: 'error', detail: 'Invalid Phone number!', life: 3000 });
                        return false; 
                    }
                    
                    if (array_to_validate[i].is_policy_holder == 1 && array_to_validate[i].email == null) {
                        this.$toast.add({ severity: 'error', detail: "email is required!", life: 3000 });
                        return false;
                    }

                    if (array_to_validate[i].date_of_birth == null) {
                        this.$toast.add({ severity: 'error', detail: "Birth date is required!", life: 3000 });
                        return false;
                    }

                    // if (array_to_validate[i].nationality == null) {
                    //     this.$toast.add({ severity: 'error', detail: "nationality is required!", life: 3000 });
                    //     return false;
                    // }
                    if (array_to_validate[i].gender == null) {
                        this.$toast.add({ severity: 'error', detail: "gender is required!", life: 3000 });
                        return false;
                    }
                    if (array_to_validate[i].gender != null && array_to_validate[i].gender.id != 1 && array_to_validate[i].is_pregnent == null) {
                        this.$toast.add({ severity: 'error', detail: "pregnent option is required!", life: 3000 });
                        return false;
                    }
                }
            }

            if (from == 'co_pay' && to == 'current_active_policy') {
                if (this.user_medical_journey.co_pay_option.length == 0) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the copay option!', life: 3000 });
                    return false; 
                }
            }
            

            if (to != 'add_family_members') {
                this.user_medical_journey.progress_obtained_mark += this.user_medical_journey.add_or_subtract_number;
                this.user_medical_journey.progress_bar_percentage = Math.round(this.user_medical_journey.progress_obtained_mark/(this.user_medical_journey.total_questions*this.user_medical_journey.progress_bar_per_question)*100)
            }
            

            
            

            scrollTo(0,250);

            this.user_medical_journey.step = to;
        },
        back: function () {
                if (this.user_medical_journey.step == 'current_insurance_company') {
                    this.user_medical_journey.step = 'current_active_policy';
                } else if (this.user_medical_journey.step == 'current_active_policy') {
                    this.user_medical_journey.step = 'salary_range';
                } else if (this.user_medical_journey.step == 'salary_range') {
                    this.user_medical_journey.step = 'critical_medical_history';
                } else if (this.user_medical_journey.step == 'critical_medical_history') {
                    if (this.user_medical_journey.coverage_type.id == 1 || this.user_medical_journey.coverage_type.id == 2) {
                        this.user_medical_journey.step = 'personal_details';
                    } else if (this.user_medical_journey.coverage_type.id == 3 || this.user_medical_journey.coverage_type.id == 4) {
                        this.user_medical_journey.step = 'family_member_details';
                    }
                } else if (this.user_medical_journey.step == 'personal_details') {
                    this.user_medical_journey.step = 'city';
                }  else if (this.user_medical_journey.step == 'family_member_details') {
                    this.user_medical_journey.step = 'add_family_members';
                }  else if (this.user_medical_journey.step == 'add_family_members') {
                    this.user_medical_journey.step = 'city';
                }  else if (this.user_medical_journey.step == 'city') {
                    this.user_medical_journey.step = 'coverage_type';
                } else if (this.user_medical_journey.step == 'coverage_type') {
                    this.$router.push({
                        name : 'medical',
                    });
                }
            if (this.user_medical_journey.step != 'add_family_members') {

                this.user_medical_journey.progress_obtained_mark -= this.user_medical_journey.add_or_subtract_number;
                this.user_medical_journey.progress_bar_percentage = Math.round(this.user_medical_journey.progress_obtained_mark/(this.user_medical_journey.total_questions*this.user_medical_journey.progress_bar_per_question)*100)

            }
            
        },
        get_companies : function() {
            axios.get(this.api_url+'get_all_companies').then((response) => {
                this.companies = response.data.data;
            });
        },
        get_salary_options : function() {
            axios.get(this.api_url+'medical/get_salary_options').then((response) => {
                this.salaries = response.data.data;
            });
        },
        get_co_pay_options : function() {
            axios.get(this.api_url+'medical/get_co_pay_options').then((response) => {
                this.co_pay_options = response.data.data;
                this.user_medical_journey.co_pay_option = this.co_pay_options;
            });
        },
        get_nationalities : function() {
            axios.get(this.api_url+'medical/get_nationalities').then((response) => {
                this.nationalities = response.data.data;
            });
        },
        get_coverage_types : function() {
            axios.get(this.api_url+'medical/get_coverage_types').then((response) => {
                this.coverage_types = response.data.data;
            });
        },
        get_gender_categories : function() {
            axios.get(this.api_url+'medical/get_gender_categories').then((response) => {
                this.gender_categories = response.data.data;
            });
        },
        get_uae_cities : function() {
            axios.get(this.api_url+'get_uae_cities').then((response) => {
                this.uae_cities = response.data.data;
            });
        },
        stop_writing_on_date : function(eve) {
            eve.preventDefault();
        },
        onInput : function(phone, obj) {
            if(obj.valid) {
                this.is_phone_number_valid = true;
            } else {
                this.is_phone_number_valid = false;
            }
        },
        valid : function(obj) {
            if (obj.valid != undefined) {
                if(obj.valid) {
                    this.is_phone_number_valid = true;
                } else {
                    this.is_phone_number_valid = false;
                }
            } else {
                this.is_phone_number_valid = false;
            }
        },
        validate_email : function (email) {
            // eslint-disable-next-line
            return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        number_with_commas : function (x) {
            // eslint-disable-next-line
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
}
</script>

<script setup>
import { ref } from 'vue';

const value = ref('EN');
const options = ref(['EN', 'العربية']);

</script>

<style></style>
