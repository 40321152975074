<template>
    <HeaderJourney />

    <div class="main-listing-page main-medical-listing-page">
        <div class="container">
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="flow-option-back flow-option-back-listing">
                        <ul class="flow-option-back-ul">
                            <li v-if="$route.query.is_from == undefined" class="flow-option-back-li"
                                @click="go_back_to_medical_journey_page('current_insurance_company')">
                                <i class="pi pi-arrow-left"></i>
                                Back
                            </li>
                            <li class="inquiry-number">
                                <span>Inquiry Number:</span> <span>{{ ('quotation_number' in quotation) ?
                                    quotation.quotation_number : '-'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0 flow-info-editable-box">
                <div class="col-12 md:col-12 lg:col-12">
                    <swiper :slides-per-view="3" :space-between="0" setWrapperSize="ture" :navigation="true" :loop="true"
                    :pagination="pagination" :modules="modules" :breakpoints="{
                            '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                            '@0.75': { slidesPerView: 2, spaceBetween: 0 },
                            '@1.00': { slidesPerView: 3, spaceBetween: 0 },
                            '@1.50': { slidesPerView: 3, spaceBetween: 0 },
                    }" @swiper="onSwiper" @slideChange="onSlideChange">
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-one') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>1</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Coverage Type</p>
                                                <h4 class="flow-type-title">{{ ('coverage_type' in quotation) ? quotation.coverage_type.name : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div class="flow-edit-box" @click="go_back_to_medical_journey_page('coverage_type')">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-two') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>2</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Emirates</p>
                                                <h4 class="flow-type-title">{{ ('city' in quotation) ? quotation.city.name : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div class="flow-edit-box" @click="edit_emirates_modal = true">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <!-- <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-three') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>3</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Critical Medical History</p>
                                                <h4 class="flow-type-title">{{ ('critical_medical_history' in quotation) ? quotation.critical_medical_history : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div class="flow-edit-box" @click="go_back_to_medical_journey_page('critical_medical_history')">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide> -->
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-four') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>3</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Salary Range</p>
                                                <h4 class="flow-type-title">{{ ('salary_range' in quotation) ? quotation.salary_range.name : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div class="flow-edit-box" @click="edit_salary_range_modal = true">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <!-- <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-five') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>4</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Current Active Policy</p>
                                                <h4 class="flow-type-title">{{ ('current_active_policy' in quotation) ? quotation.current_active_policy : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div class="flow-edit-box" @click="go_back_to_medical_journey_page('current_active_policy')">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide> -->
                    </swiper>
                </div>
            </div>

            <div class="grid ml-0 mr-0 mb-4">
                <div class="col-12 md:col-4 lg:col-3 listing-filter-area">
                    <div class="left-filters-area">
                        <div class="filter-options-area">
                            <div class="filter-option-list mt-0">
                                <h4>Insurance Companies</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime
                                            v-model="selected_medical_company" @change="get_plans" :inputId="'company_0'" name="company"
                                            :value="0" />
                                        <label for="company_0" class="ml-2">All Comapnies</label>
                                    </div>
                                    <div class="flex align-items-center" v-for="item in medical_companies" :key="item">
                                        <radioButton-prime
                                            v-model="selected_medical_company" @change="get_plans" :inputId="'company_'+item.id"
                                            name="company" :value="item.id" />
                                        <label :for="'company_'+item.id" class="ml-2">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-option-list">
                                <h4>Hospital Network</h4>

                                <div class="flex medical-filter-dropdown hospital-filter" style="position:relative;">
                                    <multiSelect-prime @change="get_plans" :showToggleAll="false" filterIcon filter v-model="hospital_filters_selected" :virtualScrollerOptions="{ itemSize: 50 }" :options="hospital_filters" optionLabel="name" placeholder="Select Hospital Network" :maxSelectedLabels="3" class="w-full">
                                        <template #option="slotProps">
                                            <span>{{slotProps.option.name}} ({{ slotProps.option.emirate }})</span>
                                        </template>
                                    </multiSelect-prime>
                                     <button-prime @click="hospital_filters_selected=[]; get_plans()" class="clear-hoispital-filter">Clear</button-prime>
                                </div>
                            </div>

                            <div class="filter-option-list border-0 pb-0">
                                <h4>Copay</h4>

                                <div class="flex medical-filter-dropdown">
                                    <multiSelect-prime @change="get_plans" v-model="co_pay_option_filters_selected" :options="co_pay_option_filters" optionLabel="percentage" placeholder="Select Copay" :maxSelectedLabels="5" class="w-full">
                                        <template #option="slotProps">
                                            <span>{{slotProps.option.percentage}} {{ slotProps.option.label }}</span>
                                        </template>
                                    </multiSelect-prime>    
                                </div>
                            </div>

                            <!-- <div class="filter-option-list">
                                <h4>Inpatient/Outpatient</h4>

                                <div class="flex medical-filter-dropdown">
                                    <multiSelect-prime @change="get_plans" v-model="io_filters_selected" :options="io_filters" optionLabel="name" placeholder="Select Inpatient/Outpatient" :maxSelectedLabels="5" class="w-full" />
                                </div>
                            </div> -->

                            
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-8 lg:col-9">
                    <div class="right-listing-items-area">
                        <div class="listing-tabs-container">
                            <button-prime class="filters p-0 listing-filter-btn-mobile" aria-label="filters"
                                @click="visible = true">
                                <span class=" px-3">Filter</span>
                                <img src="../../assets/images/sort.svg">
                            </button-prime>

                            <div class="listing-tab-panel">
                                <!-- <div class="listing-tab-btn active">
                                    Premium
                                </div>
                                <div class="listing-tab-btn">
                                    Basic
                                </div> -->
                            </div>
                            
                            <div class="listing-sort-container">
                                <dropdown-prime @change="get_plans()" v-model="selected_sort"
                                    :options="sort" optionLabel="name"
                                    placeholder="Sort By" class="w-full" />
                            </div>
                        </div>

                        <div class="tabs-listing-content">
                            <!-- Plans Not Found -->
                            <div class="tabs-listing-box company-plans plans-not-found" v-if="plans != null && plans.length == 0">
                                <i class="pi pi-exclamation-triangle"></i>
                                <h1>Plans not found</h1>
                            </div>
                            <!-- / Plans Not Found -->
                            
                            <!-- listing loader -->
                            <template v-if="plans == null">
                                <div class="tabs-listing-box company-plans plans-loader" v-for="index in 3" :key="index">
                                    <div class="top-row">
                                        <Skeleton-prime shape="circle"></Skeleton-prime>
                                        <div class="top-row-right">
                                            <Skeleton-prime class="mb-2" ></Skeleton-prime>
                                            <Skeleton-prime width="10rem"  class="mb-2"></Skeleton-prime>
                                            <Skeleton-prime width="5rem"  class="mb-2"></Skeleton-prime>
                                            <Skeleton-prime class="mb-2"></Skeleton-prime>
                                        </div>
                                    </div>
                                    <div class="bottom-row">
                                        <Skeleton-prime width="10rem" height="4rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem" height="4rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem" height="4rem"></Skeleton-prime>
                                    </div>
                                    <div class="bottom-row">
                                        <Skeleton-prime width="10rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem"></Skeleton-prime>
                                    </div>
                                </div>
                            </template>
                            <!-- listing loader -->

                            <!-- Medical listing -->
                            <template v-if="plans != null && plans.length > 0">
                                <div class="plan-listing-slider-view company-plans-carousel" v-for="(company_plan, company_index) in plans" :key="company_index" :data-company-id="company_plan[0].company_id">
                                    <div class="logo-box-container">
                                        <div class="logo-box">
                                            <img :src="company_plan[0].company_logo">
                                        </div>
                                        <div class="info-listing-text">
                                            <p class="company-name-text">{{ company_plan[0].company_name }}</p>
                                            <p class="plan-name-text">{{ company_plan.length }} Plan(s)</p>
                                        </div>
                                    </div>
                                    
                                    <swiper :slides-per-view="1" :data-company-id="company_plan[0].company_id" :space-between="0" :centeredSlides="true" setWrapperSize="ture" :navigation="true" :loop="false" :pagination="pagination" :modules="modules" :breakpoints="{
                                            '@0.00': 1,
                                            '@0.75': 1,
                                            '@1.00': 1,
                                            '@1.50': 1,
                                    }" @swiper="onSwiper" @slideChange="onSlideChange">
                                        <swiper-slide v-for="(plan, index) in company_plan" :key="index">
                                                <div class="plan-listing-slider-view">
                                                    <div class="tabs-listing-box company-plans">
                                                        <div class="listing-top-header">
                                                            <div class="header-info-right">
                                                                <div class="plan-info-top-left-column">
                                                                    <div class="currency-info">
                                                                        <div class="annual-premium">
                                                                            <span class="regular-text">Annual Premium</span>
                                                                            <span class="bold-text">AED {{number_format(plan.gross_premium)}}</span>
                                                                        </div>
                                                                        <div class="vat" v-if="plan.basma>0">
                                                                            <span class="regular-text">Basma</span>
                                                                            <span class="bold-text">AED {{number_format(plan.basma)}}</span>
                                                                        </div>
                                                                        <div class="vat">
                                                                            <span class="regular-text">VAT (5%)</span>
                                                                            <span class="bold-text">AED {{number_format(plan.tax_amount)}}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div class="medical-badge-text">
                                                                        <p class="plan-name-text">{{ plan.plan_name }}</p>
                                                                        <!-- <p class="plan-name-text">All</p> -->
                                                                    </div>
                                                                </div>
                                                                <div class="btn-right-area">
                                                                    <!-- <small>Pay in 4 installments at AED {{number_format((parseFloat(plan.premium)+parseFloat(plan.tax_amount))/100*25)}}/month</small> -->
                                                                    <button-prime label="Buy Now" class="buy-now-btn" :loading="loading_buy_now_button"
                                                                    @click="update_quotation_details(plan)" />
                                                                    <div class="footer-download-send-btn">
                                                                        <button-prime icon="pi pi-download" class="medical-pi-download" :loading="download_pdf_loader" label="Download" severity="secondary" @click="generate_pdf(plan)" outlined />
                                                                        <!-- <button-prime icon="pi pi-send" severity="secondary" outlined /> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="listing-main-content">
                                                            <div class="middle-content-box medi-middle-content-box">
                                                                <div class="icon-area">
                                                                    <img src="../../assets/images/Icons/network.svg">
                                                                </div>
                                                                <div class="info-area">
                                                                    <h4>Network Coverage</h4>
                                                                    <p>Hospitals, Clinics</p>

                                                                    <router-link class="icon-area-anchor" :to="'/medical/plan/hospital-network/'+plan.plan_id">
                                                                        See Network
                                                                    </router-link>
                                                                </div>
                                                            </div>
                                                            <div class="middle-content-box medi-middle-content-box">
                                                                <div class="icon-area">
                                                                    <!-- <img src="../../assets/images/Icons/cross.svg"> -->
                                                                </div>
                                                                <div class="info-area">
                                                                    <h4>Inpatient/Outpatient?</h4>
                                                                    <p>
                                                                        <span v-for="(item, itemindex) in plan.plan_type" :key="itemindex">
                                                                            {{ item }}{{ (itemindex==plan.plan_type.length-1 ? '' : ', ') }}
                                                                        </span>
                                                                    </p>
                                                                </div>

                                                                <i class="pi pi-info-circle info-icon-pi" v-tooltip.top="'Lorem ipsum is placeholder text'"></i>
                                                            </div>
                                                            <div class="middle-content-box medi-middle-content-box">
                                                                <div class="icon-area">
                                                                    <!-- <img src="../../assets/images/Icons/cross.svg"> -->
                                                                </div>
                                                                <div class="info-area">
                                                                    <h4>Copay Covered</h4>
                                                                    <p>{{ plan.plan_co_pay_percentage }}%</p>

                                                                    <!-- <a class="icon-area-anchor" href="#" @click="fullDetailMaternityVisible = true">See full details</a> -->
                                                                </div>

                                                                <i class="pi pi-info-circle info-icon-pi" v-tooltip.top="'Lorem ipsum is placeholder text'"></i>
                                                            </div>
                                                        </div>

                                                        <div class="listing-footer">
                                                            <div class="footer-likn-text">
                                                                <a href="#">
                                                                    Terms & Condition
                                                                </a>
                                                            </div>
                                                            <div class="footer-collapse">
                                                                <button-prime class="panel-open-btn" label="Explore Benefits and Ad-Ons"
                                                                    severity="secondary" outlined />
                                                            </div>
                                                            <div class="compare-box">
                                                                <button-prime class="p-0 transparent" aria-label="compare" @click="open_compare_footer(plan)">
                                                                    <span class="px-2">Add to Compare</span>
                                                                    <i class="pi pi-plus px-2"></i>
                                                                </button-prime>
                                                            </div>
                                                        </div>

                                                        <div class="listing-footerInfo">
                                                            <accordion-prime v-model:activeIndex="active">
                                                                <accordionTab-prime>
                                                                    <h2 class="listing-footerInfo-title">What's Included</h2>

                                                                    <div class="includes-info-list-container">
                                                                        <template v-for="included_benefit in plan.plan_benefits" :key="included_benefit" >
                                                                            <div class="includes-info-list"  v-if="included_benefit.benefit_status=='included'">
                                                                                <div class="includes-info-list-icon">
                                                                                </div>
                                                                                <h2 class="includes-info-title">- {{ included_benefit.benefit_name }}
                                                                                    <span v-if="included_benefit.benefit_value!=null">({{included_benefit.benefit_value}})</span>
                                                                                </h2>
                                                                            </div>
                                                                        </template>
                                                                    </div>

                                                                    <h2 class="listing-footerInfo-title mt-5">Choose the add ons</h2>

                                                                    <div class="addons-info-list-container medical-addons-info-list-container">
                                                                        <template v-for="optional_benefit in plan.plan_benefits" :key="optional_benefit">
                                                                        <div class="addons-info-box" v-if="optional_benefit.benefit_status=='optional'">
                                                                            <div class="addons-info-area">
                                                                                <div class="addons-header">
                                                                                    <div class="addons-icon">
                                                                                        <checkbox-prime @change="add_benefit_in_premium(index, optional_benefit, $event)" v-model="plans[index].selected_addons" :value="optional_benefit"/>
                                                                                    </div>
                                                                                    <div class="medical-addons-list-left">
                                                                                        <h2 class="addons-title">{{ optional_benefit.benefit_name }}</h2>
                                                                                        <p class="addons-description" v-if="optional_benefit.benefit_value!=null">
                                                                                            {{optional_benefit.benefit_value}}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="addons-price-add">
                                                                                <div class="addons-prine-box">
                                                                                    <p class="m-0 addons-pricing-area">
                                                                                        <span class="currency-label">AED</span>
                                                                                        <span class="price-label">{{optional_benefit.benefit_amount}}</span>
                                                                                    </p>
                                                                                    <p class="m-0 per-year">per year</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </template>
                                                                        
                                                                    </div>
                                                                </accordionTab-prime>
                                                            </accordion-prime>
                                                        </div>
                                                    </div>
                                                </div>
                                            </swiper-slide>
                                    </swiper>
                                </div>
                            </template>
                            <!-- / Medical listing -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="compare-items-bottom" style="display:flex !important;" v-if="medical_compare.length>0">
            <div class="container">
                <div class="compare-box-inner">
                    <div class="compare-left">
                        <div class="compare-item" v-for="(compare, index1) in medical_compare" :key="index1">
                            <div class="compare-item-info">
                                <label>{{compare.company_name}}</label>
                                <h2>AED {{number_format(compare.premium)}}</h2>
                            </div>
                            <div class="compare-item-delete" @click="remove_compare_index(index1)">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>


                    </div>

                    <div class="compare-right">
                        <button-prime label="Compare All" @click="compare_all" class="compare-all-btn" />
                        <p class="clear-all" @click="clear_comparision_footer">Clear All</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Filter Modal For Mobile -->
    <dialog-prime v-model:visible="visible" header="Filters" :style="{ width: '25rem' }" position="bottom" :modal="true"
        :draggable="false" class="listing-filter-modal-mobile">
        <div class="height-100">
            <div class="grid ml-0 mr-0 height-100">
                <div class="col-12 md:col-12 lg:col-12 listing-filter-area-mobile height-100">
                    <div class="left-filters-area">
                        <div class="filter-options-area">
                            <div class="filter-option-list mt-0">
                                <h4>Insurance Companies</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime
                                            v-model="selected_medical_company" @change="get_plans" :inputId="'company_0'" name="company"
                                            :value="0" />
                                        <label for="company_0" class="ml-2">All Comapnies</label>
                                    </div>
                                    <div class="flex align-items-center" v-for="item in medical_companies" :key="item">
                                        <radioButton-prime
                                            v-model="selected_medical_company" @change="get_plans" :inputId="'company_'+item.id"
                                            name="company" :value="item.id" />
                                        <label :for="'company_'+item.id" class="ml-2">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-option-list">
                                <h4>Hospital Network</h4>

                                <div class="flex medical-filter-dropdown hospital-filter" style="position:relative;">
                                    <multiSelect-prime @change="get_plans" :showToggleAll="false" filterIcon filter v-model="hospital_filters_selected" :virtualScrollerOptions="{ itemSize: 50 }" :options="hospital_filters" optionLabel="name" placeholder="Select Hospital Network" :maxSelectedLabels="3" class="w-full">
                                        <template #option="slotProps">
                                            <span>{{slotProps.option.name}} ({{ slotProps.option.emirate }})</span>
                                        </template>
                                    </multiSelect-prime>
                                     <button-prime @click="hospital_filters_selected=[]; get_plans()" class="clear-hoispital-filter">Clear</button-prime>
                                </div>
                            </div>

                            <div class="filter-option-list border-0 pb-0">
                                <h4>Copay</h4>

                                <div class="flex medical-filter-dropdown">
                                    <multiSelect-prime @change="get_plans" v-model="co_pay_option_filters_selected" :options="co_pay_option_filters" optionLabel="percentage" placeholder="Select Copay" :maxSelectedLabels="5" class="w-full">
                                        <template #option="slotProps">
                                            <span>{{slotProps.option.percentage}} {{ slotProps.option.label }}</span>
                                        </template>
                                    </multiSelect-prime>    
                                </div>
                            </div>
                        </div>
                    </div>

                    <button-prime label="Apply filters" class="apply-filter-btn" @click="visible = false" />
                </div>
            </div>
        </div>
    </dialog-prime>
    <!-- / Filter Modal For Mobile -->

    <!-- Meternity Full detail Modal  -->
    <dialog-prime v-model:visible="fullDetailMaternityVisible" modal :draggable="false" header="Maternity Coverage" class="listing-full-detail-modal" :style="{ width: '45rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <p class="mb-5 mt-0">
            Maternity Coverage, designed to provide comprehensive support throughout your pregnancy journey. Our commitment is to ensure you receive the best care, from pre-natal to post-natal stages.
        </p>

        <div class="mb-5">
            <h4 class="detail-heading">Pre-Natal Care:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Regular Check-ups</h4>
                    <p>Access to a network of experienced obstetricians for routine examinations.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Diagnostic Tests</h4>
                    <p>Coverage for essential pre-natal tests ensuring the well-being of both mother and baby.</p>
                </div>
            </div>
        </div>

        <div class="mb-5">
            <h4 class="detail-heading">Delivery:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Hospital Coverage</h4>
                    <p>Choose from our extensive network of hospitals for a comfortable and secure delivery.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Labour and Delivery Expenses</h4>
                    <p>Coverage for hospital charges related to labor and delivery.</p>
                </div>
            </div>
        </div>

        <div class="mb-5">
            <h4 class="detail-heading">Post-Natal Care:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Post-Delivery Check-ups</h4>
                    <p>Continued coverage for post-natal consultations and care.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Well-Baby Check-ups</h4>
                    <p>Ensure your new born's health with coverage for well-baby check-ups.</p>
                </div>
            </div>
        </div>
    </dialog-prime>
    <!-- / Meternity Full detail Modal  -->

    <!-- Meternity Full detail Modal  -->
    <dialog-prime v-model:visible="fullDetailSurgeryVisible" modal :draggable="false" header="Surgery Coverage" class="listing-full-detail-modal" :style="{ width: '45rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <p class="mb-5 mt-0">
            Maternity Coverage, designed to provide comprehensive support throughout your pregnancy journey. Our commitment is to ensure you receive the best care, from pre-natal to post-natal stages.
        </p>

        <div class="mb-5">
            <h4 class="detail-heading">Pre-Natal Care:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Regular Check-ups</h4>
                    <p>Access to a network of experienced obstetricians for routine examinations.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Diagnostic Tests</h4>
                    <p>Coverage for essential pre-natal tests ensuring the well-being of both mother and baby.</p>
                </div>
            </div>
        </div>

        <div class="mb-5">
            <h4 class="detail-heading">Delivery:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Hospital Coverage</h4>
                    <p>Choose from our extensive network of hospitals for a comfortable and secure delivery.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Labour and Delivery Expenses</h4>
                    <p>Coverage for hospital charges related to labor and delivery.</p>
                </div>
            </div>
        </div>

        <div class="mb-5">
            <h4 class="detail-heading">Post-Natal Care:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Post-Delivery Check-ups</h4>
                    <p>Continued coverage for post-natal consultations and care.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Well-Baby Check-ups</h4>
                    <p>Ensure your new born's health with coverage for well-baby check-ups.</p>
                </div>
            </div>
        </div>
    </dialog-prime>
    <!-- / Meternity Full detail Modal  -->

    <!-- Edit Medical Emirates Modal -->
    <dialog-prime v-model:visible="edit_emirates_modal" modal header="Choose your emirate" class="edit-flow-modal" :style="{ width: '40rem' }">
        <div class="edit-flow-modal-mody">
            <div class="step-container one-flow height-auto flow-destinations">
                <div class="flow-step-container">
                    <div class="search-box-field">
                        <IconField-prime iconPosition="right">
                            <InputIcon-prime class="pi pi-search"> </InputIcon-prime>
                            <inputText-prime @keyup="searchEmirate" v-model="searchemirate" placeholder="Search your emirate" />
                        </IconField-prime>
                    </div>

                    <ScrollPanel-prime class="content-scroll-panel-travel mt-0">
                        <div class="select-list-item emirate-list" v-for="item in uae_cities" :key="item" @click="update_emirate(item)">
                            <div class="select-list-item-box mb-0">
                                <div class="country-img">
                                    <img src="../../assets/images/Icons/uae.png">
                                </div>
                                <h2 class="select-list-item-name">{{ item.name }}</h2>
                            </div>
                        </div>
                    </ScrollPanel-prime>
                </div>
            </div>
        </div>
        <div class="flex justify-content-end gap-2">
            <button-prime type="button" label="Cancel" severity="secondary" @click="edit_emirates_modal = false"></button-prime>
        </div>
    </dialog-prime>
    <!-- / Edit Medical Emirates Modal -->

    <!-- Edit Salary Range Modal -->
    <dialog-prime v-model:visible="edit_salary_range_modal" modal header="Select your salary range" class="edit-flow-modal" :style="{ width: '40rem' }">
        <div class="edit-flow-modal-mody">
            <div class="step-container three-flow height-auto">
                <div class="flow-step-container">
                    <div class="select-list-item mt-0">
                        <div class="select-list-item-box range-salary-box" v-for="item in salaries" :key="item" @click="update_salary_range(item)">
                            <h2 class="salary-range-number">{{ item.name }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-content-end gap-2">
            <button-prime type="button" label="Cancel" severity="secondary" @click="edit_salary_range_modal = false"></button-prime>
        </div>
    </dialog-prime>
     <!-- / Edit Salary Range Modal -->

    <toast-prime />
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios'
import $ from 'jquery'
// import moment from 'moment'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    data() {
        return {
            modules: [
                Navigation, Pagination, Autoplay, Scrollbar
            ],
            edit_emirates_modal: false,
            edit_salary_range_modal: false,
            searchemirate : null,
            quotation : {},
            uae_cities : [],
            salaries : [],
            plans : null,
            loading_buy_now_button : false,
            medical_compare : [],
            hospital_network_filters : [],
            hospital_network_filters_selected : [],
            hospital_filters : [],
            hospital_filters_selected : [],
            co_pay_option_filters : [],
            co_pay_option_filters_selected : [],
            io_filters : [{name : 'Inpatient'}, {name : 'Outpatient'}],
            io_filters_selected : [],
            sort : [{ name: 'High to Low Premium', id: 'high-to-low-premium' },{ name: 'Low to High Premium', id: 'low-to-high-premium' }],
            selected_sort : null,
            medical_companies : [],
            selected_medical_company : 0,
            download_pdf_loader : false
        }
    },
    components: {
        HeaderJourney,
        Swiper,
        SwiperSlide,
    },
    mounted() {
        this.get_quotation_details();
        this.get_medical_companies();
        this.get_hospitals();
        this.get_salary_options();
        this.get_uae_cities();
        this.get_co_pay_options();
        $(document).off('click', '.panel-open-btn').on('click', '.panel-open-btn', function () {
            var $ele = this;
            if ($($ele).parents('.tabs-listing-box:first').hasClass('accordion-open')) {
                $($ele).parents('.tabs-listing-box:first').removeClass('accordion-open')
            } else {
                $('.tabs-listing-box').removeClass('accordion-open')
                $($ele).parents('.tabs-listing-box:first').addClass('accordion-open')
            }
        })
        $.expr[":"].contains = $.expr.createPseudo(function(arg) {
            return function( elem ) {
                return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
            };
        });
    },
    methods: {
        update_salary_range : function(item) {
            this.salary_range = item;
            this.update_quotation();
        },
        update_emirate : function(item) {
            this.emirate = item;
            this.update_quotation();
        },
        update_quotation : function() {
            axios.get(this.api_url+'medical/update_quotation_details',{
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    city_id : this.emirate == null ? null : this.emirate.id,
                    salary_range_id : this.salary_range == null ? null : this.salary_range.id,
                }
            }).then((response) => {
                if (response.data.data == true) {
                    this.edit_emirates_modal = false;
                    this.edit_salary_range_modal = false;
                    this.get_quotation_details();
                }
            });
        },
        searchEmirate : function() {
            $('.emirate-list').removeClass('hidden');
            $('.emirate-list:not(:contains('+ this.searchemirate +'))').addClass('hidden');
        },
        number_format : function(number) {
            number = parseFloat(number);
            return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },
        get_uae_cities : function() {
            axios.get(this.api_url+'get_uae_cities').then((response) => {
                this.uae_cities = response.data.data;
            });
        },
        get_salary_options : function() {
            axios.get(this.api_url+'medical/get_salary_options').then((response) => {
                this.salaries = response.data.data;
            });
        },
        get_medical_companies : function() {
            axios.get(this.api_url+'medical/get_medical_companies').then((response) => {
                this.medical_companies = response.data.data;
            });
        },
        get_hospital_networks : function() {
            axios.get(this.api_url+'medical/get_hospital_networks').then((response) => {
                this.hospital_network_filters = response.data.data.hospital_networks;
            });
        },
        get_hospitals : function() {
            axios.get(this.api_url+'medical/get_all_hospitals').then((response) => {
                this.hospital_filters = response.data.data.hospitals;
            });
        },
        get_co_pay_options : function() {
            axios.get(this.api_url+'medical/get_co_pay_options').then((response) => {
                this.co_pay_option_filters = response.data.data;
            });
        },
        generate_pdf : function (plan) {
            // var url = this.api_url+'medical/download-company-plan-quotation-web?quotation_id='+this.$route.params.quotation_id+'&plan_id='+plan.plan_id+'&plan_name='+plan.plan_name+'&company_name='+plan.company_name+'&plan_premium='+plan.premium+'&tax='+plan.tax_amount;
            // window.open(url, '_blank');

            this.download_pdf_loader = true;
            axios.post(this.api_url+'medical/download_company_plan_quotation', {
                quotation_id : this.$route.params.quotation_id,
                plan_id : plan.plan_id,
                plan_name : plan.plan_name,
                company_name : plan.company_name,
                plan_premium : plan.premium,
                tax : plan.tax_amount,
                co_pay : plan.plan_co_pay_percentage
            }, {
                responseType: 'blob', // Important to set response type to blob for binary data
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                // Create a URL for the Blob object
                const url = window.URL.createObjectURL(new Blob([response.data]));
                
                // Create a temporary <a> element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'quotation.pdf'); // Set the default filename

                // Append the <a> element to the body and trigger a click to start the download
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.download_pdf_loader = false;
            })
            .catch(error => function(){
                console.error('Error downloading PDF:', error)
                this.download_pdf_loader = true;
            });
        },
        add_benefit_in_premium : function(plan_index, benefit_data, event) {
            if (event.target.checked) {
                this.plans[plan_index].gross_premium = parseFloat(this.plans[plan_index].gross_premium)+parseFloat(benefit_data.benefit_amount); 
            } else {
                this.plans[plan_index].gross_premium = parseFloat(this.plans[plan_index].gross_premium)-parseFloat(benefit_data.benefit_amount); 
            }
        },
        go_back_to_medical_journey_page : function(step) {
            if (localStorage.getItem('mebrokers.customer.medical.journey') != null) {
                var parse = JSON.parse(localStorage.getItem('mebrokers.customer.medical.journey'));
                parse.step = step;
                localStorage.setItem('mebrokers.customer.medical.journey', JSON.stringify(parse))
            }
            this.$router.push({
                name : 'medical-journey',
            });
        },
        get_quotation_details : function() {
            axios.get(this.api_url+'medical/get_quotation_details', {
                params : {
                    quotation_id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.quotation = response.data.data.quotation;
                this.get_plans();
            });
        },
        get_plans : function get_plans() {
            this.plans = null;
            axios.get(this.api_url+'medical/get_plans', {
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    hospital_id : this.hospital_filters_selected,
                    co_pay : this.co_pay_option_filters_selected,
                    io : this.io_filters_selected,
                    sort_by : this.selected_sort != null ? this.selected_sort.id : null,
                    company_id : this.selected_medical_company
                }
            }).then((response) => {
                this.plans = response.data.data;
            });
        },
        update_quotation_details : function(data) {
            this.loading_buy_now_button = true;
            axios.get(this.api_url+'medical/update_quotation_details',{
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    company_id : data.company_id,
                    plan_id : data.plan_id,
                    additional_coverages : data.selected_addons,
                    premium : data.premium,
                    basma : data.basma,
                    status : 'pending-review'
                }
            }).then((response) => {
                // this.loading_buy_now_button = false;
                if (response.data.data == true) {
                    if (this.$route.query.is_from != undefined && this.$route.query.is_from == 'admin') {
                        this.$toast.add({ severity: 'success', detail: 'Changes saved!', life: 3000 });
                    } else {

                        axios.get(this.api_url+'medical/send_email_and_sms_on_request_complete',{
                            params : {
                                quotation_id : this.$route.params.quotation_id,
                            }
                        }).then((response) => {
                            this.loading_buy_now_button = false;
                            if (response.data.data.send_email == true) {
                                this.$router.push({
                                    path : '/medical/purchase-completed/'+this.$route.params.quotation_id,
                                });
                            }
                        });

                        // this.$router.push({
                        //     path : '/medical/purchase-completed/'+this.$route.params.quotation_id,
                        // });
                    }
                    
                }
            });
        },
        open_compare_footer(data) {
            
            if (data.plan_benefits.length==0){
                
                    this.$toast.add({ severity: 'error', detail: 'This plan has no benefits to compare!', life: 3000 });
                    return false;
                
            }
            if(this.medical_compare.length == 3) {
                this.$toast.add({ severity: 'error', detail: 'Maximum range for comparison is only 3!', life: 3000 });
                return false;
            } else {
                this.medical_compare.push({
                    company_id : data.company_id,
                    plan_id : data.plan_id,
                    plan_name : data.plan_name,
                    company_name : data.company_name,
                    premium : data.premium,
                    copay : data.plan_co_pay_percentage
                });
            }
        },
        clear_comparision_footer() {
            this.medical_compare = [];
            localStorage.removeItem('mebrokers.customer.medical.plans.comparison');
        },
        remove_compare_index : function(ind) {
            this.medical_compare.splice(ind, 1); 
        },
        compare_all : function() {
            localStorage.removeItem('mebrokers.customer.medical.plans.comparison');
            localStorage.setItem('mebrokers.customer.medical.plans.comparison', JSON.stringify(this.medical_compare));
            this.$router.push({
                path : '/medical/comparison-sheet/'+this.$route.params.quotation_id,
            });
        },
    }
}
</script>

<script setup>
import { ref } from 'vue';
const active = ref(1);

const visible = ref(false);
const fullDetailMaternityVisible = ref(false);
const fullDetailSurgeryVisible = ref(false);
</script>

<style>

</style>