import {
  createApp
} from 'vue'
import PrimeVue from 'primevue/config';
import App from './App.vue'

//theme
import 'primevue/resources/themes/aura-light-green/theme.css'
import '/node_modules/primeflex/primeflex.css';
import ToastService from 'primevue/toastservice';
//core
import "primevue/resources/primevue.min.css";

import './assets/css/style.css';
import './assets/css/rtl.css';

import VOtpInput from "vue3-otp-input";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import 'intl-tel-input/build/css/intlTelInput.css';

import {createI18n} from 'vue-i18n';
import EN from './locale/en.json';
import AR from './locale/ar.json';

const app = createApp(App);

if (localStorage.getItem('mebrokers_language') == null) {
  localStorage.setItem('mebrokers_language', 'EN');
}

const i18n = createI18n({
  locale : localStorage.getItem('mebrokers_language'), 
  messages : {
      EN : EN,
      AR : AR
  }
});

import {
  createRouter,
  createWebHistory
} from 'vue-router'

import homeMotorView from './components/motor-insurance/HomeMotorView.vue';
import motorJourney from './components/motor-insurance/motorJourney.vue'
import motorPlanListing from './components/motor-insurance/motorPlanListing.vue';
import comparisonSheet from './components/motor-insurance/comparisonSheet.vue';
import uploadDocument from './components/motor-insurance/uploadDocument.vue';
import checkOut from './components/motor-insurance/checkOut.vue';
import purchaseComplete from './components/motor-insurance/purchaseComplete.vue'
import motorPaymentSuccess from './components/motor-insurance/paymentSuccess.vue'

import homeTravelView from './components/travel-insurance/HomeTravelView.vue';
import travelJourney from './components/travel-insurance/travelJourney.vue';
import travelPlanListing from './components/travel-insurance/travelPlanListing.vue';
import travelComparisonSheet from './components/travel-insurance/comparisonSheet.vue';
import travelCheckOut from './components/travel-insurance/travelCheckOut.vue';
import travelpurchaseComplete from './components/travel-insurance/travelPurchaseComplete.vue'
import travelPaymentSuccess from './components/travel-insurance/paymentSuccess.vue'

import homeMedicalView from './components/medical-insurance/HomeMedicalView.vue';
import medicalJourney from './components/medical-insurance/medicalJourney.vue';
import medicalPlanListing from './components/medical-insurance/medicalPlanListing.vue';
import medicalComparisonSheet from './components/medical-insurance/comparisonSheet.vue';
import medicalpurchaseComplete from './components/medical-insurance/medicalPurchaseComplete.vue';
import medicalCheckOut from './components/medical-insurance/medicalCheckOut.vue';
import medicalPaymentSuccess from './components/medical-insurance/paymentSuccess.vue'
import termsConditions from './components/pages/termsConditions.vue';
import privacyPolicy from './components/pages/privacyPolicy.vue';
import medicalHospitalNetwork from './components/medical-insurance/medicalHospitalNetwork.vue';
import medicalCriticalConditionSuccessPage from './components/medical-insurance/medicalCriticalConditionSuccessPage.vue';
import HomeView from './components/HomeView.vue';
import notFound from './components/pages/notFound.vue'

//Components
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import SelectButton from 'primevue/selectbutton';
import Menubar from 'primevue/menubar';
import Carousel from 'primevue/carousel';
import Avatar from 'primevue/avatar';
import Message from 'primevue/message';
import Rating from 'primevue/rating';
import Paginator from 'primevue/paginator';
import Galleria from 'primevue/galleria';
import ProgressBar from 'primevue/progressbar';
import Dialog from 'primevue/dialog';
import Slider from 'primevue/slider';
import Textarea from 'primevue/textarea';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import RadioButton from 'primevue/radiobutton';
import Menu from 'primevue/menu';
import TieredMenu from 'primevue/tieredmenu';
import Calendar from 'primevue/calendar'
import AutoComplete from 'primevue/autocomplete';
import FileUpload from 'primevue/fileupload';
import Sidebar from 'primevue/sidebar';
import Breadcrumb from 'primevue/breadcrumb';
import MegaMenu from 'primevue/megamenu';
import ScrollTop from 'primevue/scrolltop';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import InputNumber from 'primevue/inputnumber';
import Toast from 'primevue/toast';
import Image from 'primevue/image';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import ScrollPanel from 'primevue/scrollpanel';
import InputOtp from 'primevue/inputotp';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';   
import Skeleton from 'primevue/skeleton';
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from 'primevue/tooltip';

app.directive('tooltip', Tooltip);

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

app.component('ScrollPanel-prime', ScrollPanel);
app.component('IconField-prime', IconField);
app.component('InputIcon-prime', InputIcon);
app.component('button-prime', Button);
app.component('dropdown-prime', Dropdown);
app.component('inputText-prime', InputText);
app.component('checkbox-prime', Checkbox);
app.component('password-prime', Password);
app.component('selectButton-prime', SelectButton);
app.component('menubar-prime', Menubar);
app.component('carousel-prime', Carousel);
app.component('avatar-prime', Avatar);
app.component('rating-prime', Rating);
app.component('paginator-prime', Paginator);
app.component('galleria-prime', Galleria);
app.component('progressBar-prime', ProgressBar);
app.component('dialog-prime', Dialog);
app.component('slider-prime', Slider);
app.component('textarea-prime', Textarea);
app.component('tabView-prime', TabView);
app.component('tabPanel-prime', TabPanel);
app.component('accordion-prime', Accordion);
app.component('accordionTab-prime', AccordionTab);
app.component('radioButton-prime', RadioButton);
app.component('menu-prime', Menu);
app.component('megamenu-prime', MegaMenu);
app.component('tieredMenu-prime', TieredMenu);
app.component('message-prime', Message);
app.component('sidebar-prime', Sidebar);
app.component('calendar-prime', Calendar);
app.component('breadcrumb-prime', Breadcrumb);
app.component('autoComplete-prime', AutoComplete);
app.component('fileupload-prime', FileUpload);
app.component('scrolltop-prime', ScrollTop);
app.component('multiSelect-prime', MultiSelect);
app.component('InputNumber-prime', InputNumber);
app.component('overlayPanel-prime', OverlayPanel);
app.component('v-otp-input', VOtpInput);
app.component('toast-prime', Toast);
app.component('Image-prime', Image);
app.component('InputOtp-prime', InputOtp);
app.component('Skeleton-prime', Skeleton);
app.component('ProgressSpinner-prime', ProgressSpinner);

app.component('DataTable-prime', DataTable);
app.component('Column-prime', Column);
app.component('ColumnGroup-prime', ColumnGroup);
app.component('Row-prime', Row);


const router = createRouter({
  history: createWebHistory(),
  routes: [{
      path: '/',
      name: 'main-home',
      component: HomeView,
      meta: {
        title: '',
        metaTags: [{
            name: '',
            content: ''
          },
          {
            property: '',
            content: ''
          }
        ]
      }
    },
    {
      path: '/motor',
      name: 'motor',
      component: homeMotorView,
    },
    {
      path: '/motor/journey',
      name: 'motor-journey',
      component: motorJourney,
    },
    {
      path: '/motor/plan-listing/:quotation_id',
      name: 'motor-plan-listing',
      component: motorPlanListing,
    },
    {
      path: '/motor/comparison-sheet/:quotation_id',
      name: 'motor-comparison-sheet',
      component: comparisonSheet,
    },
    {
      path: '/motor/upload-documents/:quotation_id',
      name: 'motor-upload-documents',
      component: uploadDocument,
    },
    {
      path: '/motor/purchase-completed/:quotation_id',
      name: 'motor-purchase-completed',
      component: purchaseComplete,
    },
    {
      path: '/motor/checkout/:quotation_id',
      name: 'motor-checkout',
      component: checkOut,
    },
    {
      path: '/motor/payment-success/:quotation_id',
      name: 'motor-payment-success',
      component: motorPaymentSuccess,
    },
    {
      path: '/travel',
      name: 'travel',
      component: homeTravelView,
    },
    {
      path: '/travel/journey',
      name: 'travel-journey',
      component: travelJourney,
    },
    {
      path: '/travel/plan-listing/:quotation_id',
      name: 'travel-plan-listing',
      component: travelPlanListing,
    },
    {
      path: '/travel/comparison-sheet/:quotation_id',
      name: 'travel-comparison-sheet',
      component: travelComparisonSheet,
    },
    {
      path: '/travel/purchase-completed/:quotation_id',
      name: 'travel-purchase-completed',
      component: travelpurchaseComplete,
    },
    {
      path: '/travel/checkout/:quotation_id',
      name: 'travel-checkout',
      component: travelCheckOut,
    },
    {
      path: '/travel/payment-success/:quotation_id',
      name: 'travel-payment-success',
      component: travelPaymentSuccess,
    },
    {
      path: '/medical',
      name: 'medical',
      component: homeMedicalView,
    },
    {
      path: '/medical/journey',
      name: 'medical-journey',
      component: medicalJourney,
    },
    {
      path: '/medical/purchase-completed/:quotation_id',
      name: 'medical-purchase-completed',
      component: medicalpurchaseComplete,
    },
    {
      path: '/medical/plan-listing/:quotation_id',
      name: 'medical-listing',
      component: medicalPlanListing,
    },
    {
      path: '/medical/comparison-sheet/:quotation_id',
      name: 'medical-comparison-sheet',
      component: medicalComparisonSheet,
    },
    {
      path: '/medical/checkout/:quotation_id',
      name: 'medical-checkout',
      component: medicalCheckOut,
    },
    {
      path: '/medical/payment-success/:quotation_id',
      name: 'medical-payment-success',
      component: medicalPaymentSuccess,
    },
    {
      path: '/medical/critical-condition-success-page/:quotation_id',
      name: 'medical-critical-condition-success-page',
      component: medicalCriticalConditionSuccessPage,
    },
    {
      path: '/terms-and-condition',
      name: 'terms-and-condition',
      component: termsConditions,
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: privacyPolicy,
    },
    {
      path: '/medical/plan/hospital-network/:plan_id',
      name: 'medical-plan-hospital-network',
      component: medicalHospitalNetwork,
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: notFound,
    },
    
  ],
  scrollBehavior(to, from) {
    // scrollBehavior (to, from, savedPosition) {
    console.log('wewewe', to, from);
    if (
      to.name == 'home' ||
      to.name == 'motor' ||
      to.name == 'motor-journey' ||
      to.name == 'motor-plan-listing' ||
      to.name == 'motor-comparison-sheet' ||
      to.name == 'motor-upload-documents' ||
      to.name == 'motor-checkout' ||
      to.name == 'motor-purchase-completed' ||
      to.name == 'motor-payment-success' ||

      to.name == 'travel' ||
      to.name == 'travel-journey' ||
      to.name == 'travel-plan-listing' ||
      to.name == 'travel-comparison-sheet' ||
      to.name == 'travel-checkout' ||
      to.name == 'travel-purchase-completed' ||
      to.name == 'travel-payment-success' ||

      to.name == 'medical' ||
      to.name == 'medical-journey' ||
      to.name == 'medical-plan-listing' ||
      to.name == 'medical-comparison-sheet' ||
      to.name == 'medical-upload-documents' ||
      to.name == 'medical-checkout' ||
      to.name == 'medical-purchase-completed' ||
      to.name == 'medical-payment-success' ||
      to.name == 'terms-and-condition' ||
      to.name == 'privacy-policy' ||
      to.name == 'medical-network'
    ) {
      // if(to.name == 'places' && from.name == 'places') {
      //   window.location.href = '/places';
      // }
      return {
        top: 0
      }
    }
  }
});

app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.use(VueTelInput);
app.use(i18n);

app.config.globalProperties.mebrokers_language = localStorage.getItem('mebrokers_language');
app.config.globalProperties.external_login_facebook = (location.host == '127.0.0.1:8080' ? 'http://MeCompare-Admin.test/api/auth/facebook' : 'https://admin.mebrokers.net/api/auth/facebook');
app.config.globalProperties.external_login_google = (location.host == '127.0.0.1:8080' ? 'http://MeCompare-Admin.test/api/auth/google' : 'https://admin.mebrokers.net/api/auth/google');
app.config.globalProperties.api_url = (location.host == '127.0.0.1:8080' ? 'http://MeCompare-Admin.test/api/' : 'https://admin.mebrokers.net/api/');
// app.config.globalProperties.api_url = 'https://admin.mebrokers.net/api/';
// app.config.globalProperties.api_url = 'http://127.0.0.1:8000/api/';
// app.config.globalProperties.api_url = 'http://MeCompare-Admin.test/api/';
app.config.globalProperties.company_name = 'MECompare';
app.config.globalProperties.company_email = 'info@mebrokers.net';
app.config.globalProperties.company_phone_number = '0900 764984';
app.config.globalProperties.company_whatsapp_number = '97124457300';

app.mount('#app');