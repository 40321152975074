<template>
    <div class="get-agent-help-container">
        <div class="agent-help-box">
            <div class="agent-image">
                <img src="../assets/images/amyelsner.png">
            </div>
            <div class="agentHelp-rightInfo">
                <i class="pi pi-times close-chat"></i>
                <h2>Chat with support</h2>
                <p>Get instant assistance from our dedicated team. </p>
                <a :href="'https://wa.me/'+company_whatsapp_number"><button-prime class="support-chatnow-btn" label="Chat Now" icon="pi pi-whatsapp" /></a>
            </div>
        </div>
    </div>

    <div class="chat-minimize-btn">
        <p>Chat with Us</p>
        <i class="pi pi-chevron-up"></i>
    </div>
</template>

<style>
.chat-minimize-btn {
    background: #25D366;
    border-color: #25D366;
    margin-top: 10px;
    border-radius: 8px;
    justify-content: center;
    position: fixed;
    right: 10px;
    color: #fff;
    width: 130px;
    bottom: 10px;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    display: none;
    align-items: center;
    column-gap: 6px;
    cursor: pointer;
}

.chat-minimize-btn p {
    margin: 0;
}

.chat-minimize-btn i.pi.pi-chevron-up {
    font-size: 12px;
}

.show-chat {
    display: flex !important;
}
</style>

<script>
import $ from 'jquery';

export default { 
    mounted() {
        $(document).off('click', '.close-chat').on('click', '.close-chat', function () {
            $('.get-agent-help-container').hide()
            $('.chat-minimize-btn').addClass('show-chat')
        })

        $(document).off('click', '.chat-minimize-btn').on('click', '.chat-minimize-btn', function () {
            $('.chat-minimize-btn').removeClass('show-chat')
            $('.get-agent-help-container').show()
        })
    },
}
</script>