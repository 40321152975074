<template>
    <HeaderJourney />

    <div class="main-upload-document-page">
        <div class="container">
            <div class="grid ml-0 mr-0 box-width-sm">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="progress-bar-container">
                        <div class="progress-head">
                            <router-link to="/">
                                <p class="progress-bar-label back-btn-flow">
                                    <i class="pi pi-home"></i>
                                    Home
                                </p>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0 box-width-sm">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="purchase-complete-icon">
                        <img src="../../assets/images/Icons/check-green.svg">
                    </div>
                    <h2 class="upload-document-title mb-1 mt-0">Payment Successfull!</h2>
                    <p class="puchase-complete-para">
                        Thank you for selecting {{ company_name }} as your insurance provider. You will receive an email shortly
                        with your policy details. In case you did not receive any email you can contact our customer support.

                        <br>
                        <a href="#">{{ company_email }}</a>

                        <br>
                        <a href="#">{{ company_phone_number }}</a>
                    </p>

                    <h2 class="upload-document-title mb-0">{{ ('external_plan_details' in quotation) ? quotation.external_plan_details.internal_quotation_number : '-'}}</h2>
                    <p class="puchase-complete-para">
                        Quote Reference Number
                    </p>

                    <div class="puchase-complete-info-box">
                        <h4>Plan Purchased Summary</h4>

                        <div class="info-flex-colunm">
                            <p class="col-width-50">
                                Travel type
                            </p>

                            <p class="col-width-50">
                                Travel Insurance
                            </p>

                        </div>

                        <div class="info-flex-colunm">
                            
                            <p class="col-width-50">
                                Coverage type
                            </p>

                            <p class="col-width-50">
                                {{ ('travel_type' in quotation) ? quotation.travel_type.name : '-'}}
                            </p>
                        </div>

                        <div class="info-flex-colunm">
                            
                            <p class="col-width-50">
                                Company
                            </p>

                            <p class="col-width-50">
                                {{ ('company' in quotation) ? quotation.company.name : '-'}}
                            </p>
                        </div>

                        <!-- <div class="info-flex-colunm">
                            
                            <p class="col-width-50">
                                Departure
                            </p>

                            <p class="col-width-50">
                                {{ ('external_plan_details' in quotation) ? quotation.external_plan_details.departure : '-'}}
                            </p>
                        </div> -->

                        <div class="info-flex-colunm">
                            
                            <p class="col-width-50">
                                Destination
                            </p>

                            <p class="col-width-50">
                                {{ ('external_plan_details' in quotation) ? quotation.external_plan_details.destination : '-'}}
                            </p>
                        </div>

                        <div class="info-flex-colunm">
                            
                            <p class="col-width-50">
                                Travel Start Date
                            </p>

                            <p class="col-width-50">
                                {{ ('external_plan_details' in quotation) ? quotation.external_plan_details.travel_start_date : '-'}}
                            </p>
                        </div>

                        <div class="info-flex-colunm">
                            
                            <p class="col-width-50">
                                Travel End Date
                            </p>

                            <p class="col-width-50">
                                {{ ('external_plan_details' in quotation) ? quotation.external_plan_details.travel_end_date : '-'}}
                            </p>
                        </div>


                    </div>

                    <div class="puchase-complete-info-box">
                        <h4>Passenger Details</h4>
                        <template v-if="('external_plan_details' in quotation)">
                        <template v-for="(passenger, index) in quotation.external_plan_details.passenger_details" :key="index"> 
                        <h4 v-if="quotation.external_plan_details.passenger_details.length>1">Passenger {{ (parseInt(index)+1) }}</h4>
                        <div class="info-flex-colunm">
                            <p class="col-width-50">
                                Passenger Name
                            </p>

                            <p class="col-width-50">
                                {{ passenger.passenger_full_name }}
                            </p>
                        </div>

                        
                        <div class="info-flex-colunm">
                            <p class="col-width-50">    
                                Birth Date
                            </p>

                            <p class="col-width-50">
                                {{ passenger.passenger_date_of_birth }}
                            </p>
                        </div>

                        <div class="info-flex-colunm">
                            <p class="col-width-50">    
                                Passport Number
                            </p>

                            <p class="col-width-50">
                                {{ passenger.passenger_passport_number }}
                            </p>
                        </div>
                        </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'
import axios from 'axios'
// import moment from 'moment'
export default {
    data() {
        return {
            quotation : {}
        }
    },
    components: {
        HeaderJourney
    },
    mounted() {
        this.get_quotation_details();
    },
    methods : {
        send_mecompare_request_complete_email_and_sms : function(data) {
            axios.get(this.api_url+'travel/send_email_and_sms_on_request_complete',{
                params : {
                    quotation_id : data.unique_id
                }
            }).then((response) => {
                console.log(response);
            });
        },
        get_quotation_details : function() {
            axios.get(this.api_url+'travel/get_quotation_details_from_id', {
                params : {
                    id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.quotation = response.data.data.quotation;
                if (this.quotation.external_plan_details != null && this.quotation.external_plan_details != '') {
                    this.quotation.external_plan_details = JSON.parse(this.quotation.external_plan_details);
                } else {
                    this.quotation.external_plan_details = {};
                }
                if (this.quotation.company.slug == 'orient') {
                    this.orient_get_policy_approval();    
                }

                if (this.quotation.company.slug == 'alliance') {
                    this.alliance_send_email();  
                    this.alliance_send_documents_email();  
                }

                this.send_mecompare_request_complete_email_and_sms(this.quotation);
                
            });
        },
        orient_get_policy_approval : function() {
            axios.get(this.api_url+'travel/orient_get_policy_approval', {
                params : {
                    id : this.$route.params.quotation_id
                }
            }).then((response) => {
                console.log(response.data.data)
            });
        },
        alliance_send_email : function () {
            // axios.get(this.api_url+'travel/alliance_send_email', {
            //     params : {
            //         id : this.$route.params.quotation_id
            //     }
            // }).then((response) => {
            //     console.log(response.data.data)
            // });
        },
        alliance_send_documents_email : function () {
            axios.get(this.api_url+'travel/alliance_send_documents_email', {
                params : {
                    id : this.$route.params.quotation_id
                }
            }).then((response) => {
                console.log(response.data.data)
            });
        }
    }
}
</script>

<style>

</style>