<template>
    <HeaderJourney />

    <div class="main-listing-page travel-listing-page travel-listing-wrapper">
        <div class="container">
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="flow-option-back flow-option-back-listing">
                        <ul class="flow-option-back-ul">
                            <li class="flow-option-back-li" @click="go_back_to_travel_journey_page('traveler_details')">
                                <i class="pi pi-arrow-left"></i>
                                Back
                            </li>
                            <li class="inquiry-number">
                                <span>Inquiry Number:</span> <span>{{ ('quotation_number' in quotation) ?
                                    quotation.quotation_number : '-'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0 flow-info-editable-box">
                <div class="col-12 md:col-12 lg:col-12">
                    <swiper :slides-per-view="('travel_type_id' in quotation) && quotation.travel_type_id == 2 ? 4 : 3" :space-between="0" setWrapperSize="ture" :navigation="true" :loop="true"
                    :pagination="pagination" :modules="modules" :breakpoints="{
                            '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                            '@0.75': { slidesPerView: 2, spaceBetween: 0 },
                            '@1.00': { slidesPerView: ('travel_type_id' in quotation) && quotation.travel_type_id == 2 ? 4 : 3, spaceBetween: 0 },
                            '@1.50': { slidesPerView: ('travel_type_id' in quotation) && quotation.travel_type_id == 2 ? 4 : 3, spaceBetween: 0 },
                    }" @swiper="onSwiper" @slideChange="onSlideChange">
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>1</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Travel Type</p>
                                                <h4 class="flow-type-title">{{ ('travel_type' in quotation) ? quotation.travel_type.name :
                                                    '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_travel_journey_page('travel_type')" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide v-if="('travel_type_id' in quotation) && quotation.travel_type_id == 2">
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>2</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Travel Area</p>
                                                <h4 class="flow-type-title">{{ ('travel_area' in quotation) ? quotation.travel_area.name :
                                                    '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="edit_travel_area_modal = true" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>3</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Travel Date</p>
                                                <h4 class="flow-type-title">{{ ('departure_date' in quotation) ?
                                                    moment(quotation.departure_date).format("DD MMMM YYYY") : '-' }}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="edit_dates_modal = true" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>4</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Number Of Traveler(s)</p>
                                                <h4 class="flow-type-title">{{ ('travelers' in quotation) ? quotation.travelers.length : '0' }}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_travel_journey_page('traveler_details')" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                   
                    </swiper>
                </div>
            </div>

            <div class="grid ml-0 mr-0 mb-4">
                <div class="col-12 md:col-4 lg:col-3 listing-filter-area">
                    <div class="left-filters-area">
                        <div class="filter-options-area">
                            <!-- <div class="filter-option-list mt-0">
                                <h4>Annual Premium</h4>

                                <div class="anual-price-range">
                                    <slider-prime v-model="valueAnual" class="w-full" />
                                    <div class="anual-price-text">
                                        <span>AED 500</span>
                                        <span>AED 500,000</span>
                                    </div>
                                </div>
                            </div> -->

                            <div class="filter-option-list border-0 pb-0 mt-0">
                                <h4>Insurance Companies</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime
                                            v-model="selected_travel_company" @change="show_hide_plans" :inputId="'company_0'" name="company"
                                            :value="0" />
                                        <label for="company_0" class="ml-2">All Comapnies</label>
                                    </div>
                                    <div class="flex align-items-center" v-for="item in travel_companies" :key="item">
                                        <radioButton-prime
                                            v-model="selected_travel_company" @change="show_hide_plans" :inputId="'company_'+item.id"
                                            name="company" :value="item.id" />
                                        <label :for="'company_'+item.id" class="ml-2">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-8 lg:col-9 right-listing-items-section">
                    <div class="right-listing-items-area">
                        <div class="listing-tabs-container">
                            <button-prime class="filters p-0 listing-filter-btn-mobile" aria-label="filters"
                                @click="visible = true">
                                <span class=" px-3">Filter</span>
                                <img src="../../assets/images/sort.svg">
                            </button-prime>

                            <div class="listing-tab-panel"></div>

                            <div class="listing-sort-container">
                                <dropdown-prime @change="sort_plans" v-model="selected_sort"
                                :options="sort" optionLabel="name"
                                    placeholder="Sort By" class="w-full" />
                            </div>
                        </div>

                        <div class="tabs-listing-content">
                            <!-- Plans Not Found -->
                            <div class="tabs-listing-box company-plans plans-not-found" v-if="plans != null && plans.length == 0">
                                <i class="pi pi-exclamation-triangle"></i>
                                <h1>Plans not found</h1>
                            </div>
                            <!-- / Plans Not Found -->
                            
                            <!-- listing loader -->
                            <template v-if="plans == null">
                                <div class="tabs-listing-box company-plans plans-loader" v-for="index in 3" :key="index">
                                    <div class="top-row">
                                        <Skeleton-prime shape="circle"></Skeleton-prime>
                                        <div class="top-row-right">
                                            <Skeleton-prime class="mb-2" ></Skeleton-prime>
                                            <Skeleton-prime width="10rem"  class="mb-2"></Skeleton-prime>
                                            <Skeleton-prime width="5rem"  class="mb-2"></Skeleton-prime>
                                            <Skeleton-prime class="mb-2"></Skeleton-prime>
                                        </div>
                                    </div>
                                    <div class="bottom-row">
                                        <Skeleton-prime width="10rem" height="4rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem" height="4rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem" height="4rem"></Skeleton-prime>
                                    </div>
                                    <div class="bottom-row">
                                        <Skeleton-prime width="10rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem"></Skeleton-prime>
                                    </div>
                                </div>
                            </template>
                            <!-- listing loader -->

                            <!-- Travel listing -->
                            <template v-if="plans != null && plans.length > 0">
                                <div class="plan-listing-slider-view company-plans-carousel" v-for="(company_plan, company_index) in plans" :key="company_index" :data-company-id="company_plan[0].company_id">
                                    <div class="logo-box-container">
                                        <div class="logo-box">
                                            <img :src="company_plan[0].company_logo">
                                        </div>
                                        <div class="info-listing-text">
                                            <p class="company-name-text">{{ company_plan[0].company_name }}</p>
                                            <p class="plan-name-text">{{ company_plan.length }} Plan(s)</p>
                                        </div>
                                    </div>
                                    
                                
                                    <swiper :slides-per-view="1" :data-company-id="company_plan[0].company_id" :space-between="0" :centeredSlides="true" setWrapperSize="ture" :navigation="true" :loop="false" :pagination="pagination" :modules="modules" :breakpoints="{
                                            '@0.00': 1,
                                            '@0.75': 1,
                                            '@1.00': 1,
                                            '@1.50': 1,
                                    }" @swiper="onSwiper" @slideChange="onSlideChange">
                                        <swiper-slide v-for="(plan, index) in company_plan" :key="index">
                                            <div class="plan-listing-slider-view">
                                                <div class="tabs-listing-box company-plans">
                                                    <div class="listing-top-header">
                                                        <div class="header-info-right">
                                                            <div class="plan-info-top-left-column">
                                                                <div class="currency-info">
                                                                    <div class="annual-premium">
                                                                        <span class="regular-text">Annual Premium</span>
                                                                        <span class="bold-text">AED {{ number_format(plan.premium) }}</span>
                                                                    </div>
                                                                    <div class="vat">
                                                                        <span class="regular-text">VAT (5%)</span>
                                                                        <span class="bold-text">AED {{ number_format(plan.tax_amount) }}</span>
                                                                    </div>
                                                                </div>
                                                                <p class="plan-name-text">{{ plan.plan_name }} {{ plan.plan_description == '' ? '' : '-' }} {{ plan.plan_description }}</p>
                                                            </div>

                                                            <div class="btn-right-area">
                                                                <!-- <small>Pay in 4 installments at AED {{number_format((parseFloat(plan.premium)+parseFloat(plan.tax_amount))/100*25)}}/month</small> -->
                                                                <button-prime label="Buy Now" class="buy-now-btn" :loading="buy_now_loader" @click="buy_now(plan)" />
                                                                <div class="footer-download-send-btn">
                                                                    <button-prime :loading="download_pdf_loader" class="travel-pi-download" label="Download" icon="pi pi-download" @click="generate_pdf(plan)" severity="secondary" outlined />
                                                                    <!-- <button-prime icon="pi pi-send" severity="secondary" outlined /> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="listing-main-content">
                                                        <div class="middle-content-box medi-middle-content-box" v-for="(ben, benindex) in plan.benefits.slice(0, 3)" :key="benindex">
                                                        <div class="info-area">
                                                                <h4>{{ben.name}}</h4>
                                                                <p>{{ben.value}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="middle-content-box medi-middle-content-box no-benifits-label" v-if="plan.benefits.length==0">
                                                        <div class="info-area">
                                                                <h4>No Benefits</h4>
                                                                <p></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="listing-footer">
                                                        <div class="footer-likn-text">
                                                            <!-- <a href="#">
                                                                Terms & Condition
                                                            </a> -->
                                                        </div>
                                                        <div class="footer-collapse">
                                                            <button-prime class="panel-open-btn" label="Explore Benefits and Ad-Ons"
                                                                severity="secondary" outlined />
                                                        </div>
                                                        <div class="compare-box">
                                                            <button-prime class="p-0 transparent" aria-label="compare" @click="open_compare_footer(plan)">
                                                                <span class="px-2">Add to Compare</span>
                                                                <i class="pi pi-plus px-2"></i>
                                                            </button-prime>
                                                        </div>
                                                    </div>

                                                    <div class="listing-footerInfo">
                                                        <accordion-prime v-model:activeIndex="active">
                                                            <accordionTab-prime>
                                                                <h2 class="listing-footerInfo-title">What's included in this policy</h2>

                                                                <div class="includes-info-list-container includes-info-list-container-travel" v-if="plan.benefits.length>0">
                                                                    <div class="includes-info-list" v-for="(benefit, index_benefit) in plan.benefits" :key="index_benefit">
                                                                        <div class="includes-info-list-icon">
                                                                            <img src="../../assets/images/Icons/checkmark.svg">
                                                                        </div>
                                                                        <h2 class="includes-info-title">{{ benefit.name }} {{ benefit.value == '' ? '' : '-' }} {{ benefit.value }}</h2>
                                                                    </div>
                                                                </div>
                                                                <div class="includes-info-list-container includes-info-list-container-travel" v-else>
                                                                    <div class="includes-info-list">
                                                                        <h2 class="includes-info-title">Benefits not found!</h2>
                                                                    </div>
                                                                </div>

                                                                <h2 class="listing-footerInfo-title mt-5">Choose the add ons</h2>

                                                                <div class="addons-info-list-container medical-addons-info-list-container" v-if="plan.addons.length>0">
                                                                    <div v-for="item in plan.addons" :key="item" class="addons-info-box">
                                                                        <div class="addons-info-area">
                                                                            <div class="addons-header">
                                                                                <div class="addons-icon">
                                                                                    <checkbox-prime v-model="plan.selected_addons" :value="item" />
                                                                                </div>
                                                                                <div class="medical-addons-list-left">
                                                                                    <h2 class="addons-title">{{ item.cover_name }}</h2>
                                                                                    <!-- <p class="addons-description">
                                                                                        Coverage for loss passport caused by any means.
                                                                                    </p> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="addons-price-add">
                                                                            <div class="addons-prine-box">
                                                                                <p class="m-0 addons-pricing-area">
                                                                                    <span class="currency-label">AED</span>
                                                                                    <span class="price-label">{{ item.cover_premium }}</span>
                                                                                </p>
                                                                                <p class="m-0 per-year">per year</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="includes-info-list-container includes-info-list-container-travel" v-else>
                                                                    <div class="includes-info-list">
                                                                        <h2 class="includes-info-title">Addons not found!</h2>
                                                                    </div>
                                                                </div>
                                                            </accordionTab-prime>
                                                        </accordion-prime>
                                                    </div>
                                                </div>
                                            </div>
                                        </swiper-slide>
                                    </swiper>
                                </div>
                            </template>
                            <!-- / Travel listing -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="compare-items-bottom" style="display:flex !important;" v-if="travel_compare.length>0">
            <div class="container">
                <div class="compare-box-inner">
                    <div class="compare-left">
                        <div class="compare-item" v-for="(compare, index1) in travel_compare" :key="index1">
                            <div class="compare-item-info">
                                <label>{{compare.company_name}}</label>
                                <h2>AED {{number_format(compare.premium)}}</h2>
                            </div>
                            <div class="compare-item-delete" @click="remove_compare_index(index1)">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>
                    </div>

                    <div class="compare-right">
                        <button-prime label="Compare All" @click="compare_all" class="compare-all-btn" />
                        <p class="clear-all" @click="clear_comparision_footer">Clear All</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Filter Modal For Mobile -->
    <dialog-prime v-model:visible="visible" header="Filters" :style="{ width: '25rem' }" position="bottom" :modal="true"
        :draggable="false" class="listing-filter-modal-mobile">
        <div class="height-100">
            <div class="grid ml-0 mr-0 mb-4 height-100">
                <div class="col-12 md:col-12 lg:col-12 listing-filter-area-mobile height-100">
                    <div class="left-filters-area">
                        <div class="filter-options-area">
                            <div class="filter-option-list mt-0 border-0 p-0">
                                <h4>Insurance Companies</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime
                                            v-model="selected_travel_company" @change="show_hide_plans" :inputId="'company_0'" name="company"
                                            :value="0" />
                                        <label for="company_0" class="ml-2">All Comapnies</label>
                                    </div>
                                    <div class="flex align-items-center" v-for="item in travel_companies" :key="item">
                                        <radioButton-prime
                                            v-model="selected_travel_company" @change="show_hide_plans" :inputId="'company_'+item.id"
                                            name="company" :value="item.id" />
                                        <label :for="'company_'+item.id" class="ml-2">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button-prime label="Apply filters" class="apply-filter-btn" @click="visible = false" />
                </div>
            </div>
        </div>
    </dialog-prime>
    <!-- / Filter Modal For Mobile -->

    <!-- Edit Travel Destination Modal -->
    <dialog-prime v-model:visible="edit_travel_area_modal" modal header="Choose your destinations" class="edit-flow-modal" :style="{ width: '40rem' }">
        <div class="edit-flow-modal-mody">
            <div class="step-container one-flow height-auto flow-destinations">
                <div class="flow-step-container">
                    <div class="search-box-field">
                        <IconField-prime iconPosition="right">
                            <InputIcon-prime class="pi pi-search"> </InputIcon-prime>
                            <inputText-prime @keyup="searchDestinations" v-model="searchdestination" placeholder="Search your destination" />
                        </IconField-prime>
                    </div>

                    <ScrollPanel-prime class="content-scroll-panel-travel mt-0">
                        <div class="select-list-item destinations-list" v-for="item in travel_areas" :key="item"
                            @click="update_travel_area(item)">
                            <div class="select-list-item-box mb-0">
                                <div class="country-img">
                                    <img src="../../assets/images/Icons/uae.png">
                                </div>
                                <h2 class="select-list-item-name">{{ item.name }}</h2>
                            </div>
                        </div>
                    </ScrollPanel-prime>
                </div>
            </div>
        </div>
        <div class="flex justify-content-end gap-2">
            <button-prime type="button" label="Cancel" severity="secondary" @click="edit_travel_area_modal = false"></button-prime>
        </div>
    </dialog-prime>
     <!-- / Edit Travel Destination Modal -->

     <!-- Edit Travel Dates Modal -->
    <dialog-prime v-model:visible="edit_dates_modal" modal header="Trip Dates" class="edit-flow-modal" :style="{ width: '40rem' }">
        <div class="edit-flow-modal-mody">
            <div class="step-container four-flow flow-insurance-date flow-no-border trip-dates-flow">
                <div class="flow-step-container border-0 p-0 height-auto mt-0">
                    <div class="col-12 md:col-12 lg:col-12 getQuote-form-area mt-0">
                        <div class="getQuote-form-area-inner grid m-0">
                            <div
                                class="col-12 md:col-6 lg:col-6 form-fields-main calender-field-travel pl-0">
                                <label class="mb-1 block">Departure Date</label>
                                <span class="p-input-icon-left">
                                    <calendar-prime v-model="quotation.departure_date" showIcon
                                                     :minDate="new Date(quotation.backend_departure_date)"
                                        iconDisplay="input" dateFormat="dd MM yy" placeholder="Enter Departure Date"  @keypress="stop_writing_on_date($event)" />
                                </span>
                            </div>

                            <div
                                class="col-12 md:col-6 lg:col-6 form-fields-main calender-field-travel pr-0">
                                <label class="mb-1 block">Return Date</label>
                                <span class="p-input-icon-left">
                                    <calendar-prime v-model="quotation.return_date" showIcon
                                                    :minDate="new Date(quotation.backend_return_date)"
                                        iconDisplay="input" dateFormat="dd MM yy" placeholder="Enter Return Date"  @keypress="stop_writing_on_date($event)" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-content-end gap-2">
            <button-prime type="button" label="Cancel" severity="secondary" @click="edit_dates_modal = false"></button-prime>
            <button-prime  type="button" label="Save" @click="update_dates"></button-prime>
        </div>
    </dialog-prime>
     <!-- / Edit Travel Dates Modal -->

    <toast-prime />
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    data() {
        return {
            modules: [
                Navigation, Pagination, Autoplay, Scrollbar
            ],
            quotation : {
                departure_date : new Date(),
                return_date : new Date(),
                backend_departure_date : new Date(),
                backend_return_date : new Date(),
            },
            plans : null,
            filters : {},
            travel_compare : [],
            buy_now_loader : false,
            sort : [{ name: 'High to Low Premium', id: 'high-to-low-premium' },{ name: 'Low to High Premium', id: 'low-to-high-premium' }],
            selected_sort : { name: 'Low to High Premium', id: 'low-to-high-premium' },
            travel_companies : [],
            selected_travel_company : 0,
            download_pdf_loader : false,
            travel_areas : [],
            edit_travel_area_modal : false,
            edit_dates_modal : false,
            travel_area : null,
            searchdestination : null
        }
    },
    components: {
        HeaderJourney,
        Swiper,
        SwiperSlide,
    },
    mounted() {
        this.get_quotation_details();
        this.get_travel_companies();
        this.get_travel_areas();
        $(document).off('click', '.panel-open-btn').on('click', '.panel-open-btn', function () {
            var $ele = this;
            if ($($ele).parents('.tabs-listing-box:first').hasClass('accordion-open')) {
                $($ele).parents('.tabs-listing-box:first').removeClass('accordion-open')
            } else {
                $('.tabs-listing-box').removeClass('accordion-open')
                $($ele).parents('.tabs-listing-box:first').addClass('accordion-open')
            }
        })
        $.expr[":"].contains = $.expr.createPseudo(function(arg) {
            return function( elem ) {
                return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
            };
        });
    },
    methods: {
        stop_writing_on_date : function(eve) {
            eve.preventDefault();
        },
        searchDestinations : function() {
            $('.destinations-list').removeClass('hidden');
            $('.destinations-list:not(:contains('+ this.searchdestination +'))').addClass('hidden');
        },
        update_dates : function() {
            this.update_quotation_details();
        },
        update_travel_area : function(item) {
            this.travel_area = item;
            this.update_quotation_details();
        },
        update_quotation_details : function() {
            axios.get(this.api_url+'travel/update_quotation_details',{
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    travel_area_id : this.travel_area == null ? null : this.travel_area.id,
                    departure_date : moment(this.quotation.departure_date).format("YYYY-MM-DD"),
                    return_date : moment(this.quotation.return_date).format("YYYY-MM-DD"),
                }
            }).then((response) => {
                if (response.data.data == true) {
                    this.edit_travel_area_modal = false;
                    this.edit_dates_modal = false;
                    this.get_quotation_details();
                }
            });
        },
        number_format : function(number) {
            number = parseFloat(number);
            return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },
        get_travel_areas : function() {
            axios.get(this.api_url+'travel/get_travel_areas').then((response) => {
                this.travel_areas = response.data.data;
            });
        },
        show_hide_plans : function() {
            if (this.selected_travel_company == 0) {
                $('.company-plans-carousel').removeClass('hidden');
            } else {
                $('.company-plans-carousel').addClass('hidden');
                var selected_company_id = this.selected_travel_company;
                $('[data-company-id='+selected_company_id+']').removeClass('hidden')
            }
            
        },
        get_travel_companies : function() {
            axios.get(this.api_url+'travel/get_travel_companies').then((response) => {
                this.travel_companies = response.data.data;
                // this.quotation.external_quotation_number = response.data.data.quotation_number;
            });
        },
        sort_plans : function () {

            for (let i = 0; i < this.plans.length; i++) {
                if (this.selected_sort.id == 'high-to-low-premium') {
                    this.plans[i].sort((a, b) => parseFloat(b.premium) - parseFloat(a.premium));
                } else if (this.selected_sort.id == 'low-to-high-premium') {
                    this.plans[i].sort((a, b) => parseFloat(a.premium) - parseFloat(b.premium));
                }
            }

            
            // this.show_hide_plans();
        },
        generate_pdf : function (plan) {
           

            // var url = this.api_url+'travel/download-company-plan-quotation-web?quotation_id='+this.$route.params.quotation_id+'&plan_id='+plan.plan_id+'&plan_name='+plan.plan_name+'&plan_description='+plan.plan_description+'&plan_premium='+plan.premium+'&tax='+plan.tax_amount+'&company_name='+plan.company_name+'&benefits='+btoa(JSON.stringify(plan.benefits))+'&pdf=0';
            // window.open(url, '_blank');

            this.download_pdf_loader = true;
            axios.post(this.api_url+'travel/download_company_plan_quotation', {
                quotation_id : this.$route.params.quotation_id,
                plan_id : plan.plan_id,
                plan_name : plan.plan_name,
                plan_description : plan.plan_description,
                company_name : plan.company_name,
                plan_premium : plan.premium,
                tax : plan.tax_amount,
                benefits : btoa(JSON.stringify(plan.benefits))
            }, {
                responseType: 'blob', // Important to set response type to blob for binary data
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                // Create a URL for the Blob object
                const url = window.URL.createObjectURL(new Blob([response.data]));
                
                // Create a temporary <a> element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'quotation.pdf'); // Set the default filename

                // Append the <a> element to the body and trigger a click to start the download
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.download_pdf_loader = false;
            })
            .catch(error => function(){
                console.error('Error downloading PDF:', error)
                this.download_pdf_loader = true;
            });
        },
        apply_cover : function(key, addon) {
            console.log('key, addon', key, addon)
            this.plans[key].selected_addons.push(addon);
            console.log('this.plans[key]', this.plans[key]);
        },
        go_back_to_travel_journey_page : function(step) {
            if (localStorage.getItem('mebrokers.customer.travel.journey') != null) {
                var parse = JSON.parse(localStorage.getItem('mebrokers.customer.travel.journey'));
                parse.step = step;
                localStorage.setItem('mebrokers.customer.travel.journey', JSON.stringify(parse))
            }
            this.$router.push({
                name : 'travel-journey',
            });
        },
        get_quotation_details : function() {
            axios.get(this.api_url+'travel/get_quotation_details', {
                params : {
                    quotation_id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.quotation = response.data.data.quotation;
                this.quotation.departure_date = new Date(this.quotation.departure_date),
                this.quotation.return_date = new Date(this.quotation.return_date),
                this.quotation.backend_departure_date = new Date(),
                this.quotation.backend_return_date = new Date(),
                this.get_plans();
            });
        },
        get_plans : function() {
            this.plans = null;
            axios.get(this.api_url+'travel/get_plans', {
                params : {
                    quotation_id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.plans = response.data.data;
                this.sort_plans();
            });
        },
        buy_now : function(item) {
            if (item.company_slug == 'orient') {
                this.orient_buy_plan(item);
            }
            if (item.company_slug == 'alliance') {
                this.alliance_buy_plan(item)
            }
        },
        orient_buy_plan : function(item) {
            this.buy_now_loader = true;
            axios.get(this.api_url+'travel/orient_buy_plan', {
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    external_quotation_number : item.external_quotation_number,
                    data : item,
                    company_id : item.company_id,
                    status : 'pending-review'
                }
            }).then((response) => {
                console.log(response.data.data.status);
                if (response.data.data.status == true) {
                    this.$router.push({
                        path : '/travel/checkout/'+this.$route.params.quotation_id,
                    });
                }
                
            });
        },
        alliance_buy_plan : function(item) {
            this.buy_now_loader = true;
            axios.get(this.api_url+'travel/alliance_buy_plan', {
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    data : item,
                    company_id : item.company_id,
                    status : 'pending-review'
                }
            }).then((response) => {
                if (response.data.data.status == true) {
                    this.$router.push({
                        path : '/travel/checkout/'+this.$route.params.quotation_id,
                    });
                }
                
            });
        },
        open_compare_footer(data) {
            if (data.benefits.length==0) {
                this.$toast.add({ severity: 'error', detail: 'There is no benefits to compare!', life: 3000 });
                return false;
            }
            // if(this.travel_compare.length < 3){
            //     var validation_for_same = true;
            //     for (var key in this.travel_compare) {
            //         if ((this.travel_compare[key].plan_id == data.plan_id) && (this.travel_compare[key].company_id == data.company_id)) {
            //             validation_for_same = false;
            //             break;
            //         }
            //     }
            //     if (!validation_for_same) {
            //         this.$toast.add({ severity: 'error', detail: 'This plan is alread selected!', life: 3000 });
            //         return false;
            //     }
            // }

            if(this.travel_compare.length == 3) {
                this.$toast.add({ severity: 'error', detail: 'Maximum range for comparison is only 3!', life: 3000 });
                return false;
            } else {
                this.travel_compare.push(data);
            }
        },
        clear_comparision_footer() {
            this.travel_compare = [];
            localStorage.removeItem('mebrokers.customer.travel.plans.comparison');
        },
        remove_compare_index : function(ind) {
            this.travel_compare.splice(ind, 1); 
        },
        compare_all : function() {
            localStorage.removeItem('mebrokers.customer.travel.plans.comparison');
            localStorage.setItem('mebrokers.customer.travel.plans.comparison', JSON.stringify(this.travel_compare));
            this.$router.push({
                path : '/travel/comparison-sheet/'+this.$route.params.quotation_id,
                query : {
                    travel_type : this.quotation.travel_type_id
                }
            });
        },
    }
}
</script>

<script setup>
import { ref } from 'vue';
const active = ref(1);

const visible = ref(false);


</script>

<style></style>